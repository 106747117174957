import React, {useEffect, useState} from "react";
import {
    Typography,
    Button,
    Form,
    notification,
    Select, DatePicker,
} from "antd";
import {get, post} from "../../../api/axios";
import { Link, useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";

const { Title } = Typography;

const AddAgentSubscriber = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [optionPackage, setOptionPackage] = useState([]);
  const [optionAgents, setOptionAgents] = useState([]);
  useEffect(() => {
    get("get-agent-packages").then(res => {
      let packages = [];
      res.data.map(packageData => {
        packages.push({ label: packageData.name_en, value: packageData.id});
        return packages;
      });
      setOptionPackage(packages);
    });
      get("get-agent-users?is_registered=true").then(res => {
          let users = [];
          res.data.map(user => {
              users.push({ label: user.id +' - '+ user.name + ' - '+ user.country_code + user.phone_number, value: user.id});
                return users;
          });
          setOptionAgents(users);
      });
  }, []);
  const onFinish = (values) => {
    setLoading(true);
    post(`agent/subscribers`, {
        ...values,
        start_date: values.start_date.format("YYYY-MM-DD"),
    }).then((res) => {
      notification.success({ message: "Saved successfully" });
      setLoading(false);
      navigate("/agent-subscribers");
    })
        .catch((err) => {
          notification.error({
            message: "Error!",
            description: err.response.data.message,
          });
          setLoading(false);
        });
  };

  return (
    <div>
      <Title level={2} className="text-main mb-2">
        <Link className="text-dark me-3" to="/agent-units">
          <ArrowLeftOutlined />
        </Link>
        Add Subscriber
      </Title>
      <Form
        className="wrapper-content"
        onFinish={onFinish}
        labelCol={{
          span: 7,
        }}
        wrapperCol={{
          span: 15,
        }}
        initialValues={{
          remember: true,
        }}
        autoComplete="off"
      >
        <div className="row">
            <div className="row">
                {/* Item */}
                <div className="col-lg-6">
                    <Form.Item
                        label="Package"
                        name="agent_package_id"
                        rules={[
                            {
                                required: true,
                                message: "This field is required!",
                            },
                        ]}
                    >
                        <Select
                            allowClear
                            showSearch
                            optionFilterProp="children"
                            className="w-100"
                            filterOption={(input, option) =>
                                (option?.label ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                            }
                            placeholder="Select"
                            options={optionPackage}
                        />
                    </Form.Item>
                </div>
                {/* Item */}
                <div className="col-lg-6">
                    <Form.Item
                        label="Agent"
                        name="user_id"
                        rules={[
                            {
                                required: true,
                                message: "This field is required!",
                            },
                        ]}
                    >
                        <Select
                            allowClear
                            showSearch
                            optionFilterProp="children"
                            className="w-100"
                            filterOption={(input, option) =>
                                (option?.label ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                            }
                            placeholder="Select"
                            options={optionAgents}
                        />
                    </Form.Item>
                </div>
                {/* Item */}
                <div className="col-lg-6">
                    <Form.Item
                        label="Start Date"
                        name="start_date"
                        rules={[
                            {
                                required: true,
                                message: "This field is required!",
                            },
                        ]}
                    >
                        <DatePicker placeholder="" className="w-100"/>
                    </Form.Item>
                </div>
                {/* Item */}
                <div className="col-lg-6">
                    <Form.Item
                        label="Duration"
                        name="duration"
                        rules={[
                            {
                                required: true,
                                message: "This field is required!",
                            },
                        ]}
                    >
                        <Select
                            allowClear
                            showSearch
                            optionFilterProp="children"
                            className="w-100"
                            filterOption={(input, option) =>
                                (option?.label ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                            }
                            placeholder="Select"
                            options={[
                                {
                                    value: "annually",
                                    label: "Annually"
                                },
                                {
                                    value: "monthly",
                                    label: "Monthly"
                                },
                                {
                                    value: "one_time",
                                    label: "One Time"
                                },
                            ]}
                        />
                    </Form.Item>
                </div>
            </div>
        </div>
          <div className="row mt-5 pt-2">
              <div className="col-lg-4 offset-lg-4">
                  <Form.Item
                      wrapperCol={{
                          offset: 1,
                          span: 16,
                      }}
                  >
                      <Button
                          loading={loading}
                          size="large"
                          block
                          type="primary"
                          htmlType="submit"
                      >
                          {loading ? "Saving..." : "Save"}
                      </Button>
                  </Form.Item>
              </div>
          </div>
      </Form>
    </div>
  );
};

export default AddAgentSubscriber;
