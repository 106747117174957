import React, {useCallback, useEffect, useState} from "react";
import {
    Button,
    Checkbox,
    DatePicker,
    Dropdown,
    Form,
    Input,
    Modal,
    notification,
    Popconfirm,
    Select,
    Space,
    Tag
} from "antd";
import {del, get, post} from "../../../api/axios";
import {Link} from "react-router-dom";
import {DeleteOutlined, DownOutlined, EditOutlined} from "@ant-design/icons";
import {checkPermission} from "../../control-sections/auth/ProtectedRoutes";
import TextArea from "antd/es/input/TextArea";
import TableComponent from "../../control-sections/helper/TableComponent";
import {onChangeTableHelper} from "../../control-sections/helper/TableHelpers";

const AgentUsers = () => {
    const [form] = Form.useForm();
    const [NotificationForm] = Form.useForm();
    const [BulkNotificationForm] = Form.useForm();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isBulkModalOpen, setIsBulkModalOpen] = useState(false);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState({
        users: []
    });
    const [filterData, setFilterData] = useState({
        filter_key: "",
        filter_value: "",
    });

    const [sorter, setSorter] = useState({
        sort_by: "id",
        order: "desc",
    });

    const [userFilters, setUserFilters] = useState({
        has_active_subscription: false,
        has_account_manager: false,
        has_active_campaigns: false,
        has_no_account_manager: false,
        has_no_active_subscription: false,
        has_no_active_campaigns: false,
    });
    const [total, setTotal] = useState(1);
    const columns = [
        {
            title: " Id",
            dataIndex: "id",
            sorter: (a, b) => a.id - b.id,
        },
        {
            title: "Gov ID Number",
            dataIndex: "id_number",
            sorter: (a, b) => a.id_number - b.id_number,
        },
        {
            title: " Account Manager",
            dataIndex: "account_manager",
            render: ( account_manager ) => (
                account_manager ? <Link to={`/agent-account-managers/edit/${account_manager.id}`}>{account_manager.name}</Link> : ""
            ) ,
        },
        {
            title: "Active Package",
            dataIndex: "package",
            render: (packageData) => (
                packageData ? <Link to={`/agent-packages/edit/${packageData.id}`}>{packageData.name}</Link> : ""
            ),
        },
        {
            title: "Start Date",
            dataIndex: "package",
            render: (packageData) => (
                packageData ? packageData.start_date : ""
            ),
        },
        {
            title: "End Date",
            dataIndex: "package",
            render: (packageData) => (
                packageData ? packageData.end_date : ""
            ),
        },
        {
            title: " Name",
            dataIndex: "name",
            sorter: (a, b) => a.name - b.name,
        },
        {
            title: "Phone Number",
            dataIndex: "full_phone_number",
            sorter: (a, b) => a.phone_number - b.phone_number,
        },
        {
            title: "Email",
            dataIndex: "email",
            sorter: (a, b) => a.email - b.email,
        },
        {
            title: "Register Date",
            dataIndex: "created_at",
            sorter: (a, b) => a.created_at - b.created_at,
        },
        {
            title: "Team Size",
            dataIndex: "team_agents_count",
            sorter: (a, b) => a.team_agents_count - b.team_agents_count,
        },
        {
            title: "Leads Count",
            dataIndex: "leads_count",
        },
        {
            title: "Reservations count",
            dataIndex: "reservations_count"
        },
        {
            title: "Total Commission",
            dataIndex: "total_agent_commission"
        },
        {
            title: "Active",
            render: ({ is_active }) => (
                <>
                    <Tag color={`${is_active ? "success" : "error"}`}>
                        {String(is_active)}
                    </Tag>
                </>
            ),
        },
    ];
    columns.push({
        title: "Dropdown",
        dataIndex: "id",
        render: (id) => (
            <Dropdown
                overlay={
                    <Space
                        style={{
                            position: "relative",
                            zIndex: "1000",
                            backgroundColor: "#eee",
                            padding: "10px",
                            borderRadius: "5px",

                        }}
                        direction="vertical">
                        <Link to={`/agent-campaigns/?user_id=${id}`} target={"_blank"}>
                            Campaigns
                        </Link>
                        <Link to={`/agent-customers/?user_id=${id}`} target={"_blank"}>
                            Customers
                        </Link>
                        <Link to={`/agent-reservations/?user_id=${id}`} target={"_blank"}>
                            Reservations
                        </Link>
                    </Space>
                }
            >
                <Button>
                    More
                    <DownOutlined />
                </Button>
            </Dropdown>
        ),
    })
    if (checkPermission("create-agent-user") || checkPermission("delete-agent-user")) {
        columns.push({
            title: "Actions",
            dataIndex: "id",
            render: (id) => (
                <div>
                    {checkPermission("create-agent-user") ? (
                        <Button className="btn btn-outline-warning btn-edit btn-sm p-0 me-3">
                            <Link
                                className="color-inherit text-decoration-none py-1 px-2 d-block"
                                to={`/agent-users/edit/${id}`}
                            >
                                <EditOutlined />
                            </Link>
                        </Button>
                    ) : (
                        <></>
                    )}
                    {checkPermission("delete-agent-user") ? (
                        <Popconfirm
                            title="Delete item"
                            description="Are you sure you want to delete this item?"
                            onConfirm={() => confirm(id)}
                            onCancel={cancel}
                            okText="OK"
                            cancelText="Cancel"
                        >
                            <Button danger className="flex-center d-inline-flex">
                                <DeleteOutlined />
                            </Button>
                        </Popconfirm>
                    ) : (
                        <></>
                    )}
                </div>
            ),
        });
    }

    const fetchUsers = () => {
        setLoading(true);
        let url = 'agent-users?';
        if (
            filterData.filter_key !== "" && filterData.filter_key !== undefined &&
            filterData.filter_value !== "" && filterData.filter_value !== undefined
        ) {
            url += `filter_key=${filterData.filter_key}&filter_value=${filterData.filter_value}&`;
        }
        if (userFilters.has_account_manager !==false && userFilters.has_account_manager !== undefined) {
            url += `has_account_manager=${userFilters.has_account_manager}&`;
        }
        if (userFilters.has_active_subscription !==false && userFilters.has_active_subscription !== undefined) {
            url += `has_active_subscription=${userFilters.has_active_subscription}&`;
        }
        if (userFilters.has_active_campaigns !==false && userFilters.has_active_campaigns !== undefined) {
            url += `has_active_campaigns=${userFilters.has_active_campaigns}&`;
        }
        if (userFilters.has_no_account_manager !==false && userFilters.has_no_account_manager !== undefined) {
            url += `has_no_account_manager=${userFilters.has_no_account_manager}&`;
        }
        if (userFilters.has_no_active_subscription !==false && userFilters.has_no_active_subscription !== undefined) {
            url += `has_no_active_subscription=${userFilters.has_no_active_subscription}&`;
        }
        if (userFilters.has_no_active_campaigns !==false && userFilters.has_no_active_campaigns !== undefined) {
            url += `has_no_active_campaigns=${userFilters.has_no_active_campaigns}&`;
        }
        if (sorter.sort_by !== "" && sorter.sort_by !== undefined) {
            url += `sort_by=${sorter.sort_by}&`;
        }
        if (sorter.order !== "" ? `order=${sorter.order}` : "") {
            url += `order=${sorter.order}`;
        }
        get(url).then((res) => {
            setData(res.data.data);
            setTotal(res.data.meta.total);
            setLoading(false);
            setLoadingSearch(false);
        })
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchUsers();
    }, [userFilters]);

    const handleDelete = (id) => {
        del(`agent-users/${id}`)
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoading(false);
            });
        let dateFilter = data.filter((item) => item.id !== id);
        setData(dateFilter);
    };
    const confirm = (id) => {
        handleDelete(id);
        notification.success({ message: "It was successful" });
    };
    const cancel = (e) => {
        notification.info({ message: "The order has been cancelled" });
    };
    const handleFilterKey = (e) => {
        setFilterData({ ...filterData, filter_key: e });
    };
    const handleFilterVal = (e) => {
        setFilterData({ ...filterData, [e.target.id]: e.target.value });
    };
    const handleFilterChange = (checked, filterKey) => {
        setUserFilters({ ...userFilters, [filterKey]: checked });
    };
    const onFilter = (values) => {
        setLoadingSearch(true);
        setFilterData({
            filter_key: values.filter_key,
            filter_value: values.filter_value,
        });
        fetchUsers();
    };

    const clearSearch = () => {
        form.resetFields();
        setFilterData({
            filter_key: "",
            filter_value: "",
        });

        fetchUsers();
    };

    const onChangeTable = (pagination, filters, sorter) => {
        setLoading(true);
        setSorter({
            ...sorter,
            sort_by: sorter.field,
            order: sorter.order == "descend" ? "desc" : "asc",
        });
        onChangeTableHelper('agent-users', pagination, filterData, sorter)
            .then((res) => {
                setData(res.data.data);
                setTotal(res.data.meta.total);
                setLoading(false);
            })
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoading(false);
            });
    };

    const showModal = () => {
        console.log(selectedUsers);
        if (selectedUsers.users.length > 0) {
            setIsBulkModalOpen(false);
            setIsModalOpen(true);
        }
        else {
            notification.error({ message: "must select one or more users" });
        }
    };

    const showBulkModal = () => {
        setIsModalOpen(false);
        setIsBulkModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setSelectedUsers({
            users: []
        });
    };
    const handleBulkCancel = () => {
        setIsBulkModalOpen(false);
    };

    const sendPushNotification = (values) => {
        post(`push-notifications`, {
            "user_ids": selectedUsers.users,
            "notification_title": values.notification_title,
            "notification_body": values.notification_body
        }).then((res) => {
            notification.success({ message: "Notification Sent successfully" });

            NotificationForm.resetFields();
            setIsModalOpen(false);
            setSelectedUsers({
                users: []
            });
        })
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoading(false);
            });
    };
    const sendBulkPushNotification = (values) => {
        post(`all-users-push-notification`, {
            "notification_title": values.notification_title,
            "notification_body": values.notification_body
        }).then((res) => {
            notification.success({ message: "Notification Sent successfully" });

            BulkNotificationForm.resetFields();
            setIsBulkModalOpen(false);
        })
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoading(false);
            });
    };

    const onChangeDate = (date, dateString) => {
        console.log(date, dateString);
        setFilterData({
            filter_key: filterData.filter_key,
            filter_value: dateString,
        })
    };

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedUsers({
                users: selectedRowKeys,
            });
        },
        getCheckboxProps: (record) => ({
            name: record.id,
        }),
    };

    return (
        <>
            <Modal title="Push Notification" open={isModalOpen}
                footer={null} forceRender onCancel={handleCancel}>
                <Form
                    form={NotificationForm}
                    className="wrapper-content"
                    onFinish={sendPushNotification}
                    labelCol={{
                        span: 7,
                    }}
                    wrapperCol={{
                        span: 15,
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    autoComplete="off">
                    <div className="row">
                        {/* Item */}
                        <div className="col-lg-12">
                            <Form.Item
                                label="Title"
                                name="notification_title"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <Input placeholder="Type..." />
                            </Form.Item>
                        </div>
                        {/* Item */}
                        <div className="col-lg-12">
                            <Form.Item
                                label="Body "
                                name="notification_body"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <TextArea showCount
                                    maxLength={250}
                                    style={{
                                        height: 120,
                                    }} />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 offset-lg-4">
                            <Form.Item
                                wrapperCol={{
                                    offset: 1,
                                    span: 16,
                                }}
                            >
                                <Button
                                    size="large"
                                    block
                                    type="primary"
                                    htmlType="submit"
                                >
                                    Send
                                </Button>
                            </Form.Item>
                        </div>
                    </div>
                </Form>
            </Modal>
            <Modal title="Bulk Notification" open={isBulkModalOpen}
                footer={null} forceRender onCancel={handleBulkCancel}>
                <Form
                    form={BulkNotificationForm}
                    className="wrapper-content"
                    onFinish={sendBulkPushNotification}
                    labelCol={{
                        span: 7,
                    }}
                    wrapperCol={{
                        span: 15,
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    autoComplete="off">
                    <div className="row">
                        {/* Item */}
                        <div className="col-lg-12">
                            <Form.Item
                                label="Title"
                                name="notification_title"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <Input placeholder="Type..." />
                            </Form.Item>
                        </div>
                        {/* Item */}
                        <div className="col-lg-12">
                            <Form.Item
                                label="Body "
                                name="notification_body"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <TextArea showCount
                                    maxLength={250}
                                    style={{
                                        height: 120,
                                    }} />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 offset-lg-4">
                            <Form.Item
                                wrapperCol={{
                                    offset: 1,
                                    span: 16,
                                }}
                            >
                                <Button
                                    size="large"
                                    block
                                    type="primary"
                                    htmlType="submit"
                                >
                                    Send
                                </Button>
                            </Form.Item>
                        </div>
                    </div>
                </Form>
            </Modal>
            <div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="row">
                            {checkPermission("update-user") && total > 0 ? (
                                <div className="text-end">
                                    {
                                        checkPermission("push-notifications") && total > 0 ? (
                                            <>
                                                {
                                                    selectedUsers.users.length > 0 ? (
                                                        <Button className="p-2 m-2" size="large"
                                                            type="primary" onClick={showModal}>
                                                            Send Notification to selected
                                                        </Button>
                                                    ) : (
                                                        <Button className="p-2 m-2" size="large"
                                                            type="primary" onClick={showBulkModal}>
                                                            Send Notification to all
                                                        </Button>
                                                    )
                                                }
                                            </>
                                        ) : (<></>)
                                    }
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                </div>
                <div className="grid-margin stretch-card wrapper-content">
                    <div className="row mb-3">
                        <div className="col-12 mt-3">
                            <Checkbox
                                onChange={(e) => handleFilterChange(e.target.checked, "has_account_manager")}
                            >
                                Has Account Manager
                            </Checkbox>
                            <Checkbox
                                onChange={(e) => handleFilterChange(e.target.checked, "has_active_subscription")}
                            >
                                Has Active Subscription
                            </Checkbox>
                            <Checkbox
                                onChange={(e) => handleFilterChange(e.target.checked, "has_active_campaigns")}
                            >
                                Has Active Campaigns
                            </Checkbox>
                        </div>
                        <div className="col-12 mt-3">

                            <Checkbox
                                onChange={(e) => handleFilterChange(e.target.checked, "has_no_account_manager")}
                            >
                                Hasn't Account Manager
                            </Checkbox>
                            <Checkbox
                                onChange={(e) => handleFilterChange(e.target.checked, "has_no_active_subscription")}
                            >
                                Hasn't Subscription
                            </Checkbox>
                            <Checkbox
                                onChange={(e) => handleFilterChange(e.target.checked, "has_no_active_campaigns")}
                            >
                                Hasn't Campaigns
                            </Checkbox>
                        </div>
                    </div>
                    <Form onFinish={onFilter} layout="vertical" form={form}>
                        <div className="row">
                            <div className="col-12 col-lg-4">
                                <Form.Item
                                    label="Search By"
                                    name="filter_key"
                                    rules={[
                                        {
                                            required: true,
                                            message: "This field is required!",
                                        },
                                    ]}
                                >
                                    <Select
                                        className="w-100"
                                        showSearch
                                        placeholder="select test "
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            (option?.label ?? "")
                                                .toLowerCase()
                                                .includes(input.toLowerCase())
                                        }
                                        onChange={handleFilterKey}
                                        options={[
                                            {
                                                value: "phone_number",
                                                label: "Phone",
                                            },
                                            {
                                                value: "email",
                                                label: "Email",
                                            },
                                            {
                                                value: "created_at",
                                                label: "Register Date",
                                            },
                                            {
                                                value: "name",
                                                label: "Name",
                                            },
                                            {
                                                value: "id_number",
                                                label: "Gov ID Number",
                                            },
                                            {
                                                value: "account_manager_name_en",
                                                label: "Account Manager En",
                                            },
                                            {
                                                value: "account_manager_name_ar",
                                                label: "Account Manager Ar",
                                            },
                                            {
                                                value: "account_manager_id",
                                                label: "Account Manager Id",
                                            },
                                            {
                                                value: "id",
                                                label: "Id",
                                            },
                                        ]}
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-12 col-lg-6">
                                <Form.Item
                                    label="Search"
                                    name="filter_value"
                                    onChange={handleFilterVal}
                                    rules={[
                                        {
                                            required: true,
                                            message: "This field is required!",
                                        },
                                    ]}
                                >

                                    {
                                        filterData.filter_key == "created_at" ?
                                            <DatePicker className="w-100" onChange={onChangeDate}/>
                                            :
                                            <Input placeholder="Type to search..."/>
                                    }
                                </Form.Item>
                            </div>
                            <div className="col-12 col-lg-2 d-flex align-items-end">
                                <Form.Item className="w-100">
                                    <div className="row">
                                        <div className="col-8">
                                            <Button
                                                block
                                                loading={loadingSearch}
                                                type="primary"
                                                htmlType="submit"
                                            >
                                                {loadingSearch ? "Searching" : "Search"}
                                            </Button>
                                        </div>
                                        <div className="col-4">
                                            <Button
                                                onClick={clearSearch}
                                                className="flex-center"
                                                danger
                                                type="primary"
                                            >
                                                <DeleteOutlined/>
                                            </Button>
                                        </div>
                                    </div>
                                </Form.Item>
                            </div>
                        </div>
                    </Form>
                    <TableComponent onChangeTable={onChangeTable} loading={loading} columns={columns}
                        data={data} rowSelection={rowSelection} total={total}/>
                </div>
            </div>
        </>
    );
};

export default AgentUsers;
