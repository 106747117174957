import React, { useEffect, useState } from "react";
import { Button, Typography, Form, Select, Input, Tag, notification } from "antd";
import { get } from "../../../api/axios";
import { DeleteOutlined } from "@ant-design/icons";
import { onChangeTableHelper } from "../../control-sections/helper/TableHelpers";
import TableComponent from "../../control-sections/helper/TableComponent";
import {checkPermission} from "../../control-sections/auth/ProtectedRoutes";
import {Link} from "react-router-dom";

const AgentSubscribers = () => {
    const { Title } = Typography;
    const [form] = Form.useForm();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingSearch, setLoadingSearch] = useState(false);

    const [total, setTotal] = useState(1);

    const columns = [
        {
            title: " Id",
            dataIndex: "id",
            sorter: (a, b) => a.id - b.id,
        },
        {
            title: "User Name",
            dataIndex: "user",
            render: (data) => (data == null ? "" : data.name)
        },
        {
            title: "package Name",
            dataIndex: "package",
            render: (data) => (data == null ? "" : data.name)
        },
        {
            title: "Start Date",
            dataIndex: "start_date",
            sorter: (a, b) => a.start_date - b.start_date
        },
        {
            title: "End Date",
            dataIndex: "end_date",
            sorter: (a, b) => a.end_date - b.end_date
        },
        {
            title: "Duration",
            dataIndex: "duration",
            sorter: (a, b) => a.duration - b.duration
        },
        // {
        //     title: "remaining campaigns",
        //     render: ({ campaigns }) => (
        //         <>
        //             {campaigns.remaining}
        //         </>
        //     )
        // },
        // {
        //     title: "remaining reservations",
        //     render: ({ reservations }) => (
        //         <>
        //             {reservations.remaining}
        //         </>
        //     )
        // },
        // {
        //     title: "remaining customers",
        //     render: ({ customers }) => (
        //         <>
        //             {customers.remaining}
        //         </>
        //     )
        // },
        {
            title: "Active",
            render: ({ is_active }) => (
                <>
                    <Tag color={`${is_active ? "success" : "error"}`}>
                        {String(is_active)}
                    </Tag>
                </>
            ),
        },
    ];

    useEffect(() => {
        get(`agent/subscribers`).then((res) => {
            setData(res.data.data);
            setTotal(res.data.meta.total);
            setLoading(false);
        });
    }, []);


    const [filterData, setFilterData] = useState({
        filter_key: "",
        filter_value: "",
    });


    const [sorter, setSorter] = useState({
        sort_by: "",
        order: "",
    });

    const handleFilterKey = (e) => {
        setFilterData({ ...filterData, filter_key: e });
    };

    const handleFilterVal = (e) => {
        setFilterData({ ...filterData, [e.target.id]: e.target.value });
    };

    const onFilter = (values) => {
        setLoadingSearch(true);


        get(
            `agent/subscribers?filter_key=${filterData.filter_key}&filter_value=${filterData.filter_value}
                ${sorter.sort_by !== "" && sorter.sort_by !== undefined ? `&sort_by=${sorter.sort_by}` : ""}${sorter.order !== "" ? `&order=${sorter.order}` : ""
            }`
        )
            .then((res) => {
                setData(res.data.data);
                setTotal(res.data.meta.total);
                setLoadingSearch(false);
            })
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoadingSearch(false);
            });
    };

    const clearSearch = () => {
        form.resetFields();
        setFilterData({
            filter_key: "",
            filter_value: ""
        });
        get(`agent/subscribers?`).then((res) => {
            setData(res.data.data);
            setTotal(res.data.meta.total);
        });
    };

    const onChangeTable = (pagination, filters, sorter) => {
        setLoading(true);
        setSorter({
            ...sorter,
            sort_by: sorter.field,
            order: sorter.order == "descend" ? "desc" : "asc",
        });

        onChangeTableHelper('agent/subscribers', pagination, filterData, sorter)
            .then((res) => {
                setData(res.data.data);
                setTotal(res.data.meta.total);
                setLoading(false);
            })
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoading(false);
            });
    };

    return (
        <div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="row">
                        <div className="col-lg-6">
                            <Title level={2} className="text-main mb-4">
                                Agent Subscriptions
                            </Title>
                        </div>
                        {checkPermission("create-agent-subscriber") ? (
                            <div className="col-lg-3 offset-lg-3 text-end">
                                <Button type="primary" className="p-0" size="large">
                                    <Link
                                        className="text-decoration-none h-100 flex-center py-2 px-4"
                                        to="/agent-subscribers/add"
                                    >
                                        Add Agent Subscriber
                                    </Link>
                                </Button>
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            </div>

            <div className="grid-margin stretch-card wrapper-content">
                <Form onFinish={onFilter} layout="vertical" form={form}>
                    <div className="row">
                        <div className="col-12 col-lg-4">
                            <Form.Item
                                label="Search By"
                                name="filter_key"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <Select
                                    className="w-100"
                                    showSearch
                                    placeholder="select test "
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label ?? "")
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                    onChange={handleFilterKey}
                                    options={[
                                        {
                                            value: "id",
                                            label: "Id",
                                        },
                                        {
                                            value: "user_name",
                                            label: "User name",
                                        },
                                        {
                                            value: "user_email",
                                            label: "User email",
                                        },
                                        {
                                            value: "user_phone",
                                            label: "phone number",
                                        },
                                        {
                                            value: "package_name",
                                            label: "Package name",
                                        },
                                    ]}
                                />
                            </Form.Item>
                        </div>
                        <div className="col-12 col-lg-6">
                            <Form.Item
                                label="Search"
                                name="filter_value"
                                onChange={handleFilterVal}
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <Input placeholder="Type to search..." />
                            </Form.Item>
                        </div>
                        <div className="col-12 col-lg-2 d-flex align-items-end">
                            <Form.Item className="w-100">
                                <div className="row">
                                    <div className="col-8">
                                        <Button
                                            block
                                            loading={loadingSearch}
                                            type="primary"
                                            htmlType="submit"
                                        >
                                            {loadingSearch ? "Searching" : "Search"}
                                        </Button>
                                    </div>
                                    <div className="col-4">
                                        <Button
                                            onClick={clearSearch}
                                            className="flex-center"
                                            danger
                                            type="primary"
                                        >
                                            <DeleteOutlined />
                                        </Button>
                                    </div>
                                </div>
                            </Form.Item>
                        </div>
                    </div>
                </Form>
                <TableComponent onChangeTable={onChangeTable} loading={loading} columns={columns} data={data} total={total} />
            </div>
        </div>
    );
};

export default AgentSubscribers;
