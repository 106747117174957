import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  Spin,
  Form,
  notification,
  Select,
} from "antd";
import { get, put } from "../../../api/axios";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import {ArrowLeftOutlined, EyeOutlined} from "@ant-design/icons";

const { Title } = Typography;

const EditAgentLicence = () => {
  const navigate = useNavigate();
  let { id } = useParams();

  const [fromLoading, setFromLoading] = useState(false);
  const [inputsValue, setInputsValue] = useState({});
    const [licenseFile, setLicenseFile] = useState(null);

  useEffect(() => {
    get(`agent-licences/${id}`).then(res => {
      setInputsValue(res.data);
      setLicenseFile(res.data.license_file);
      setFromLoading(true);
    });
  }, [id]);

  const [loading, setLoading] = useState(false);


  const onFinish = values => {
    setLoading(true);
    const newValues = {
      ...values,
    }

    put(`agent-licences/${id}`, newValues)
      .then(res => {
        notification.success({ message: "Modified successfully" });
        setLoading(false);
        navigate("/agent-licences");
      })
      .catch(err => {
        notification.error({
          message: "Error!",
          description: err.response.data.message,
        });
        setLoading(false);
      });
  };

  return (
    <div>
      <Title level={2} className="text-main mb-2">
        <Link className="text-dark me-3" to="/agent-licences">
          <ArrowLeftOutlined />
        </Link>
        Edit agent customer
      </Title>
      {fromLoading ? (
        <Form
          className="wrapper-content"
          onFinish={onFinish}
          labelCol={{
            span: 9,
          }}
          wrapperCol={{
            span: 14,
          }}
          initialValues={{
            status: inputsValue.status,
          }}
        >
          <div className="row">
            {/* Item */}
            <div className="col-lg-6">
              <Form.Item
                label="status"
                name="status"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  className="w-100"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  placeholder="Select"
                  options={[
                    {
                      label: "Accepted",
                      value: 'accepted'
                    },
                    {
                      label: "Rejected",
                      value: 'rejected'
                    },
                  ]}
                />
              </Form.Item>
            </div>
            <div className="col-lg-6">
              <Link to={licenseFile} target="_blank">
                <EyeOutlined />
                {' Preview License File'}
              </Link>
            </div>


          </div>
          <div className="row">
            <div className="col-lg-4 offset-lg-4">
              <Form.Item
                wrapperCol={{
                  offset: 1,
                  span: 16,
                }}
              >
                <Button
                  loading={loading}
                  size="large"
                  block
                  type="primary"
                  htmlType="submit"
                >
                  {loading ? "Updating..." : "Update"}
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      ) : (
        <div className="text-center">
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

export default EditAgentLicence;
