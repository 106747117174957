import { Layout, Menu } from "antd";
import React, { useState, useEffect } from "react";
import AppRoutes from "../../../AppRoutes";
import { Link, NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { ReactComponent as Logo } from "../../../assets/img/logo.svg";
import Navbar from "./Navbar";
import { checkPermission } from "../auth/ProtectedRoutes";

const { Content, Sider } = Layout;
const LayoutComponent = () => {
    let location = useLocation();
    const [collapsed, setCollapsed] = useState(false);

    const itemsMenu = [
        {
            label: 'Investor Menu',
            key: '1',
            children: [
                {
                    label: "Dashboard",
                    type: "divider"
                },
                {
                    key: "/",
                    label: <NavLink to="/public"> Dashboard</NavLink>,
                },

                {
                    key: "/statistic-categories",
                    className: !checkPermission("list-statistic-categories") && "d-none",
                    label: (
                        <NavLink to="/statistic-categories">
                            Statistic Categories
                        </NavLink>
                    ),
                },
                {
                    key: "/statistics",
                    className: !checkPermission("list-statistics") && "d-none",
                    label: (
                        <NavLink to="/statistics">
                            Statistics
                        </NavLink>
                    ),
                },
                {
                    key: "/statistic-values",
                    className: !checkPermission("list-statistic-values") && "d-none",
                    label: (
                        <NavLink to="/statistic-values">
                            Statistic Values
                        </NavLink>
                    ),
                },
                {
                    key: "/unit-types",
                    className: !checkPermission("list-unit-types") && "d-none",
                    label: (
                        <NavLink to="/unit-types">
                            Unit Types
                        </NavLink>
                    ),
                },
                {
                    key: "/packages",
                    className: !checkPermission("list-packages") && "d-none",
                    label: (
                        <NavLink to="/packages">
                            Investor Packages
                        </NavLink>
                    ),
                },
                {
                    key: "/subscription-histories",
                    className: !checkPermission("list-subscribers") && "d-none",
                    label: (
                        <NavLink to="/subscription-histories">
                            Investor Subscriptions
                        </NavLink>
                    ),
                },
                {
                    label: "Map",
                    type: "divider"
                },
                {
                    key: "/cities",
                    className: !checkPermission("list-cities") && "d-none",
                    label: (
                        <NavLink to="/cities">
                            Cities
                        </NavLink>
                    ),
                },
                {
                    key: "/neighborhoods",
                    className: !checkPermission("list-neighborhoods") && "d-none",
                    label: (
                        <NavLink to="/neighborhoods">
                            Neighborhoods
                        </NavLink>
                    ),
                },
                {
                    key: "/landmarks",
                    className: !checkPermission("list-landmarks") && "d-none",
                    label: (
                        <NavLink to="/landmarks">
                            Landmarks
                        </NavLink>
                    ),
                },
                {
                    label: "requests",
                    type: "divider"
                },
                {
                    key: "/invest-requests",
                    className: !checkPermission("list-invest-requests") && "d-none",
                    label: (
                        <NavLink to="/invest-requests">
                            Invest Requests
                        </NavLink>
                    ),
                },
                {
                    key: "/feedbacks",
                    className: !checkPermission("list-feedbacks") && "d-none",
                    label: (
                        <NavLink to="/feedbacks">
                            Feedbacks
                        </NavLink>
                    ),
                },
                {
                    key: "/contact-messages",
                    className: !checkPermission("list-contact-messages") && "d-none",
                    label: (
                        <NavLink to="/contact-messages">
                            Investor Messages
                        </NavLink>
                    ),
                },
                {
                    key: "/users",
                    className: !checkPermission("list-users") && "d-none",
                    label: (
                        <NavLink to="/users">
                            Users
                        </NavLink>
                    ),
                },
                {
                    key: "/interests",
                    className: !checkPermission("list-interests") && "d-none",
                    label: (
                        <NavLink to="/interests">
                            Interests
                        </NavLink>
                    ),
                },
                {
                    key: "/companies",
                    className: !checkPermission("list-companies") && "d-none",
                    label: (
                        <NavLink to="/companies">
                            Companies
                        </NavLink>
                    ),
                },
                {
                    key: "/menus",
                    className: !checkPermission("list-menus") && "d-none",
                    label: (
                        <NavLink to="/menus">
                            Investors Menu
                        </NavLink>
                    ),
                },

                {
                    key: "/sliders",
                    className: !checkPermission("list-sliders") && "d-none",
                    label: (
                        <NavLink to="/sliders">
                            Onboarding Sliders
                        </NavLink>
                    ),
                },
                {
                    key: "/invest-questions",
                    className: !checkPermission("list-invest-question") && "d-none",
                    label: (
                        <NavLink to="/invest-questions">
                            Invest Questions
                        </NavLink>
                    ),
                },
                {
                    key: "/estimation-questions",
                    className: !checkPermission("list-estimation-question") && "d-none",
                    label: (
                        <NavLink to="/estimation-questions">
                            Estimation Questions
                        </NavLink>
                    ),
                },
                {
                    key: "/estimation-multipliers",
                    className: !checkPermission("list-estimation-multiplier") && "d-none",
                    label: (
                        <NavLink to="/estimation-multipliers">
                            Estimation Multipliers
                        </NavLink>
                    ),
                },
                {
                    key: "/estimates",
                    className: !checkPermission("list-estimates") && "d-none",
                    label: (
                        <NavLink to="/estimates">
                            Estimates
                        </NavLink>
                    ),
                },
                {
                    key: "/variation-categories",
                    className: !checkPermission("list-variation-categories") && "d-none",
                    label: (
                        <NavLink to="/variation-categories">
                            Variation Categories
                        </NavLink>
                    ),
                },
                {
                    key: "/variations",
                    className: !checkPermission("list-variations") && "d-none",
                    label: (
                        <NavLink to="/variations">
                            variations
                        </NavLink>
                    ),
                },
                {
                    key: "/variation-values",
                    className: !checkPermission("list-variation-values") && "d-none",
                    label: (
                        <NavLink to="/variation-values">
                            Variation Values
                        </NavLink>
                    ),
                },
                {
                    key: "/Ratings",
                    className: !checkPermission("list-ratings") && "d-none",
                    label: (
                        <NavLink to="/ratings">
                            Investor Ratings
                        </NavLink>
                    ),
                },
                {
                    key: "/settings",
                    className: !checkPermission("list-settings") && "d-none",
                    label: (
                        <NavLink to="/settings">
                            Settings
                        </NavLink>
                    ),
                },
            ]
        }, {
            label: 'Agent Menu',
            key: '2',
            children: [
                {
                    key: "/agent-account-managers",
                    className: !checkPermission("list-agent-account-managers") && "d-none1",
                    label: (
                        <NavLink to="/agent-account-managers">
                            Account Managers
                        </NavLink>
                    ),
                },
                {
                    key: "/agent-private-properties",
                    className: !checkPermission("list-agent-private-properties") && "d-none1",
                    label: (
                        <NavLink to="/agent-private-properties">
                            Private Properties
                        </NavLink>
                    ),
                },
                {
                    key: "/agent-projects",
                    className: !checkPermission("list-agent-projects") && "d-none",
                    label: (
                        <NavLink to="/agent-projects">
                            Projects
                        </NavLink>
                    ),
                },

                // {
                //     key: "/agent-units",
                //     className: !checkPermission("list-agent-project-units") && "d-none",
                //     label: (
                //         <NavLink to="/agent-units">
                //             Units
                //         </NavLink>
                //     ),
                // },
                // {
                //     key: "/agent-project-questions",
                //     className: !checkPermission("list-agent-project-questions") && "d-none",
                //     label: (
                //         <NavLink to="/agent-project-questions">
                //             Project Questions
                //         </NavLink>
                //     ),
                // },
                {
                    key: "/agent-users",
                    className: !checkPermission("list-agent-users") && "d-none",
                    label: (
                        <NavLink to="/agent-users">
                            Users
                        </NavLink>
                    ),
                },
                {
                    key: "/agent-subscribers",
                    // className: !checkPermission("list-agent-users") && "d-none",
                    label: (
                        <NavLink to="/agent-subscribers">
                            Subscriptions
                        </NavLink>
                    ),
                },

                {
                    key: "/agent-packages",
                    className: !checkPermission("list-agent-packages") && "d-none",
                    label: (
                        <NavLink to="/agent-packages">
                            Subscription Packages
                        </NavLink>
                    ),
                },
                {
                    key: "/agent-teams",
                    className: !checkPermission("list-agent-teams") && "d-none",
                    label: (
                        <NavLink to="/agent-teams">
                            Teams
                        </NavLink>
                    ),
                },
                {
                    key: "/agent-team-questions",
                    className: !checkPermission("list-agent-team-questions") && "d-none",
                    label: (
                        <NavLink to="/agent-team-questions">
                            Teams Questions
                        </NavLink>
                    ),
                },
                {
                    key: "/campaign-packages",
                    className: !checkPermission("list-campaign-packages") && "d-none",
                    label: (
                        <NavLink to="/campaign-packages">
                            Campaign Packages
                        </NavLink>
                    ),
                },
                {
                    key: "/agent-campaigns",
                    className: !checkPermission("list-campaigns") && "d-none",
                    label: (
                        <NavLink to="/agent-campaigns">
                            Campaigns
                        </NavLink>
                    ),
                },
                {
                    key: "/agent-customers",
                    // className: !checkPermission("list-agent-customers") && "d-none",
                    label: (
                        <NavLink to="/agent-customers">
                            Customers
                        </NavLink>
                    ),
                },
                {
                    key: "/agent-reservations",
                    className: !checkPermission("list-agent-reservations") && "d-none",
                    label: (
                        <NavLink to="/agent-reservations">
                            Reservations
                        </NavLink>
                    ),
                },
                {
                    key: "/agent-messages",
                    className: !checkPermission("list-agent-messages") && "d-none",
                    label: (
                        <NavLink to="/agent-messages">
                            Messages
                        </NavLink>
                    ),
                },
                {
                    key: "/agent-training-categories",
                    className: !checkPermission("list-agent-training-categories") && "d-none",
                    label: (
                        <NavLink to="/agent-training-categories">
                            Training Categories
                        </NavLink>
                    ),
                },
                {
                    key: "/agent-trainings",
                    className: !checkPermission("list-agent-trainings") && "d-none",
                    label: (
                        <NavLink to="/agent-trainings">
                            Trainings
                        </NavLink>
                    ),
                },
                {
                    key: "/agent-menus",
                    className: !checkPermission("list-agent-menus") && "d-none",
                    label: (
                        <NavLink to="/agent-menus">
                            Menus
                        </NavLink>
                    ),
                },
                {
                    key: "/agent-dropdowns",
                    className: !checkPermission("list-agent-dropdowns") && "d-none",
                    label: (
                        <NavLink to="/agent-dropdowns">
                            Dropdowns
                        </NavLink>
                    ),
                },
                {
                    key: "/agent-whatsapp-messages",
                    className: !checkPermission("list-agent-whatsapp-messages") && "d-none",
                    label: (
                        <NavLink to="/agent-whatsapp-messages">
                            Whatsapp Messages
                        </NavLink>
                    ),
                },
                {
                    key: "/agent-my-account-menus",
                    className: !checkPermission("list-agent-my-account-menus") && "d-none",
                    label: (
                        <NavLink to="/agent-my-account-menus">
                            My Account Menus
                        </NavLink>
                    ),
                },
                {
                    key: "/agent-ratings",
                    className: !checkPermission("list-agent-ratings") && "d-none",
                    label: (
                        <NavLink to="/agent-ratings">
                            Ratings
                        </NavLink>
                    ),
                },
                {
                    key: "/agent-settings",
                    className: !checkPermission("list-agent-settings") && "d-none",
                    label: (
                        <NavLink to="/agent-settings">
                            Settings
                        </NavLink>
                    ),
                },
                {
                    key: "/agent-licences",
                    className: !checkPermission("list-agent-licences") && "d-none",
                    label: (
                        <NavLink to="/agent-licences">
                            Licences
                        </NavLink>
                    ),
                }
            ]
        }, {
            label: 'Global Settings',
            key: '3',
            children: [
                {
                    key: "/sliders-agent",
                    className: !checkPermission("list-onboarding-sections") && "d-none",
                    label: (
                        <NavLink to="/sliders-agent">
                            Sliders Agent
                        </NavLink>
                    ),
                },
                {
                    key: "/admins",
                    className: !checkPermission("list-admins") && "d-none",
                    label: (
                        <NavLink to="/admins">
                            Admins
                        </NavLink>
                    ),
                },
                {
                    key: "/roles",
                    className: !checkPermission("list-roles") && "d-none",
                    label: (
                        <NavLink to="/roles">
                            Roles
                        </NavLink>
                    ),
                },
                {
                    key: "/pages",
                    className: !checkPermission("list-page-translations") && "d-none",
                    label: (
                        <NavLink to="/pages">
                            Page translations
                        </NavLink>
                    ),
                },
                {
                    key: "/messages-translations",
                    className: !checkPermission("list-messages-translations") && "d-none",
                    label: (
                        <NavLink to="/messages-translations">
                            Messages translations
                        </NavLink>
                    ),
                },
            ]
        },
    ];
    const [layout, setLayout] = useState(true);

    useEffect(() => {
        if (
            location.pathname == "/login" ||
            location.pathname == '/forget-password' ||
            location.pathname == '/otp' ||
            location.pathname.includes('/campaigns/') ||
            location.pathname == '/reset-password'
        ) {
            setLayout(false);
        } else {
            setLayout(true);
            localStorage.setItem('lastPath', location.pathname)
        }

        const collection = document.querySelector(".ant-menu-item .active");
        if (collection) {
            collection.parentNode.parentNode.classList.add("item-selected");
        }
    }, [location.pathname]);
    return (
        <>
            {layout ? (
                <Layout hasSider>
                    <Sider
                        collapsible
                        collapsed={collapsed}
                        onCollapse={(value) => setCollapsed(value)}
                        theme="dark"
                        breakpoint="lg"
                        style={{
                            overflow: 'auto',
                            height: '100vh',
                            position: 'fixed',
                            insetInlineStart: 0,
                            top: 0,
                            bottom: 0,
                            scrollbarWidth: 'thin',
                            scrollbarColor: 'unset',
                        }}
                        collapsedWidth="0"
                    >
                        <div className="demo-logo-vertical">
                            <Link className="text-center h-100" to="/public">
                                <Logo/>
                            </Link>
                        </div>
                        <Menu
                            theme="dark"
                            mode="inline"
                            defaultSelectedKeys={location.pathname}
                            items={itemsMenu}
                        />
                    </Sider>
                    <Layout>
                    <Navbar />
                        <Content
                            style={{
                                margin: "24px 16px 0",
                                marginInlineStart: 300,
                            }}
                        >
                            <div
                                className="px-3"
                                style={{
                                    padding: "0 24",
                                    minHeight: 360,
                                    // background: colorBgContainer,
                                }}
                            >
                                <AppRoutes />
                            </div>
                        </Content>
                    </Layout>
                </Layout>
            ) : (
                <AppRoutes />
            )}
        </>
    );
};
export default LayoutComponent;
