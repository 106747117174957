import React, { useEffect, useState } from "react";
import {
    Input,
    Typography,
    Button,
    Spin,
    Form,
    Select,
    Switch,
    Card,
    notification,
} from "antd";
import { get, put } from "../../../api/axios";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { ArrowLeftOutlined, CloseOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";

const { Title } = Typography;

const EditEstimationQuestion = () => {
    const navigate = useNavigate();
    let { id } = useParams();
    const [form] = Form.useForm();

    const [fromLoading, setFromLoading] = useState(false);
    const [loading, setLoading] = useState(false);

    const [inputsValue, setInputsValue] = useState({});




    useEffect(() => {
        get(`estimation-questions/${id}`).then((res) => {
            setInputsValue(res.data.data);
            console.log('res.data.data', res.data.data);
            setFromLoading(true);
        });
    }, [id]);


    const handleSwitch = (status, name) => {
        setInputsValue({ ...inputsValue, [name]: status });
    };



    const [dataApplicablePropertyTypes, setDataApplicablePropertyTypes] = useState([]);
    const [optionApplicablePropertyTypes, setOptionApplicablePropertyTypes] = useState([]);

    useEffect(() => {
        setOptionApplicablePropertyTypes(
            dataApplicablePropertyTypes.map((d) => ({
                value: d.id,
                label: d.name_en,
            }))
        );
    }, [dataApplicablePropertyTypes]);

    useEffect(() => {
        get("get-all-unit-types").then((res) => {
            setDataApplicablePropertyTypes(res.data);
        });
    }, []);



    const onFinish = (values) => {
        setLoading('loading');

        if (!values.answers) {
            values.answers = []
        } else {
            values.answers.map((item, index) => {
                if (item.is_active == undefined) {
                    item.is_active = false
                }
                return item;
            })
        }


        put(`estimation-questions/${id}`, { ...values })
            .then((res) => {
                notification.success({ message: "Modified successfully" });
                setLoading('');
                navigate("/estimation-questions");
            })
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoading(false);
            });
    };



    return (
        <div>
            <Title level={2} className="text-main mb-2">
                <Link className="text-dark me-3" to="/estimation-questions">
                    <ArrowLeftOutlined />
                    Edit Estimation Question
                </Link>
            </Title>
            {fromLoading ? (
                <Form
                    className="wrapper-content"
                    onFinish={onFinish}
                    form={form}
                    labelCol={{
                        span: 7,
                    }}
                    wrapperCol={{
                        span: 15,
                    }}
                    initialValues={{
                        name_ar: inputsValue.name_ar,
                        name_en: inputsValue.name_en,
                        info_ar: inputsValue.info_ar,
                        info_en: inputsValue.info_en,
                        order: inputsValue.order,
                        is_active: inputsValue.is_active,
                        is_applied: inputsValue.is_applied,
                        is_units_area: inputsValue.is_units_area,
                        accept_zero: inputsValue.accept_zero,
                        type: inputsValue.type,
                        answers: inputsValue.answers,
                        applicable_property_types: inputsValue.applicable_property_types
                    }}
                >
                    <div className="row">


                        <div className="col-lg-6">
                            <Form.Item
                                label="Name Ar "
                                name="name_ar"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <Input placeholder="Type..." />
                            </Form.Item>
                        </div>
                        <div className="col-lg-6">
                            <Form.Item
                                label="Name En "
                                name="name_en"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <Input placeholder="Type..." />
                            </Form.Item>
                        </div>
                        <div className="col-lg-6">
                            <Form.Item
                                label="Info Ar "
                                name="info_ar"
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: "This field is required!",
                            //     },
                            // ]}
                            >
                                <TextArea placeholder="Type..." ></TextArea>
                            </Form.Item>
                        </div>
                        <div className="col-lg-6">
                            <Form.Item
                                label="Info En "
                                name="info_en"
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: "This field is required!",
                            //     },
                            // ]}
                            >
                                <TextArea placeholder="Type..." ></TextArea>
                            </Form.Item>
                        </div>
                        <div className="col-lg-6">
                            <Form.Item
                                label="Order"
                                name="order"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <Input type="number" placeholder="Type..." />
                            </Form.Item>
                        </div>

                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item
                                label="Applicable Property"
                                name="applicable_property_types"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <Select
                                    mode="multiple"
                                    allowClear
                                    showSearch
                                    optionFilterProp="children"
                                    className="w-100"
                                    filterOption={(input, option) =>
                                        (option?.label ?? "")
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                    placeholder="Select"
                                    options={optionApplicablePropertyTypes}
                                />
                            </Form.Item>
                        </div>

                        <div className="col-lg-6">
                            <Form.Item label="Active" name="is_active">
                                <Switch
                                    defaultChecked={inputsValue.is_active}
                                    onChange={(status) => handleSwitch(status, "is_active")}
                                />
                            </Form.Item>
                        </div>
                        <div className="col-lg-6">
                            <Form.Item label="Applied" name="is_applied">
                                <Switch
                                    defaultChecked={inputsValue.is_applied}
                                    onChange={(status) => handleSwitch(status, "is_applied")}
                                />
                            </Form.Item>
                        </div>
                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item
                                label="Type"
                                name="type"
                            >
                                <Select
                                    className="w-100"
                                    filterOption={(input, option) =>
                                        (option?.label ?? "")
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                    placeholder="Select"
                                    options={[
                                        {
                                            label: 'Choice',
                                            value: 'choice'
                                        },
                                        {
                                            label: 'Number',
                                            value: 'number'
                                        },
                                    ]}
                                    onChange={v => setInputsValue({ ...inputsValue, 'type': v })}
                                />
                            </Form.Item>
                        </div>
                        <div className="col-lg-6">
                            <Form.Item label="Allow Skipping" name="accept_zero">
                                <Switch
                                    defaultChecked={inputsValue.accept_zero}
                                    onChange={(status) => handleSwitch(status, "accept_zero")}
                                />
                            </Form.Item>
                        </div>

                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item label="Unit Area Question" name="is_units_area">
                                <Switch defaultChecked={inputsValue.is_units_area} onChange={(status) => handleSwitch(status, "is_units_area")}
                                />
                            </Form.Item>
                        </div>

                        {inputsValue.type == 'choice' &&

                            <div className="col-lg-12">
                                <Card
                                    size="small"
                                >
                                    {/* Nest Form.List */}
                                    <Form.Item label="Answers?">
                                        <Form.List name='answers'>
                                            {(subFields, subOpt) => (
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        rowGap: 16,
                                                    }}
                                                >
                                                    {subFields.map((subField) => (
                                                        <div className="row" key={subField.key}>
                                                            <div className="col-lg-6">
                                                                <Form.Item
                                                                    wrapperCol={{
                                                                        offset: 1,
                                                                        span: 18,
                                                                    }}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: "This field is required!",
                                                                        }
                                                                    ]}
                                                                    label='Name Ar' name={[subField.name, 'name_ar']}>
                                                                    <Input placeholder="Type..." />
                                                                </Form.Item>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <Form.Item
                                                                    wrapperCol={{
                                                                        offset: 1,
                                                                        span: 18,
                                                                    }}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: "This field is required!",
                                                                        }
                                                                    ]}
                                                                    label='Name En' name={[subField.name, 'name_en']}>
                                                                    <Input placeholder="Type..." />
                                                                </Form.Item>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <Form.Item
                                                                    wrapperCol={{
                                                                        offset: 1,
                                                                        span: 18,
                                                                    }}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: "This field is required!",
                                                                        }
                                                                    ]}
                                                                    label='Order' name={[subField.name, 'order']}>
                                                                    <Input className="ms-3" type="number" placeholder="Type..." />
                                                                </Form.Item>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <Form.Item
                                                                    wrapperCol={{
                                                                        offset: 1,
                                                                        span: 18,
                                                                    }}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: "This field is required!",
                                                                        }
                                                                    ]}
                                                                    label='Weight' name={[subField.name, 'weight']}>
                                                                    <Input className="ms-3" type="number" placeholder="Type..." />
                                                                </Form.Item>
                                                            </div>
                                                            <div className="col-lg-5">
                                                                <Form.Item
                                                                    wrapperCol={{
                                                                        offset: 3,
                                                                        span: 16,
                                                                    }}
                                                                    label='Active' name={[subField.name, 'is_active']}>
                                                                    <Switch
                                                                        defaultChecked={inputsValue.answers[subField.name] ? inputsValue.answers[subField.name].is_active : false}
                                                                    />
                                                                </Form.Item>
                                                            </div>
                                                            <div className="col-lg-1 text-end">
                                                                <CloseOutlined
                                                                    className="border"
                                                                    onClick={() => {
                                                                        subOpt.remove(subField.name);
                                                                    }}
                                                                />
                                                            </div>

                                                        </div>
                                                    ))}
                                                    <Button type="dashed" onClick={() => subOpt.add()} block>
                                                        + Add Other Answer
                                                    </Button>
                                                </div>
                                            )}
                                        </Form.List>
                                    </Form.Item>
                                </Card>

                                {/* <Form.Item noStyle shouldUpdate>
                                {() => (
                                    <Typography>
                                        <pre>{JSON.stringify(form.getFieldsValue(), null, 2)}</pre>
                                    </Typography>
                                )}
                            </Form.Item> */}
                            </div>
                        }




                        <div className="col-lg-4 offset-lg-4">
                            <Form.Item
                                wrapperCol={{
                                    offset: 1,
                                    span: 16,
                                }}
                            >
                                <Button
                                    loading={loading == 'loading' ? true : loading == 'uploading' ? true : false}
                                    size="large"
                                    block
                                    type="primary"
                                    htmlType="submit"
                                >
                                    {loading == 'loading' ? "Updating..." : loading == 'uploading' ? 'Uploading' : "Update"}
                                </Button>
                            </Form.Item>
                        </div>
                    </div>
                </Form>
            ) : (
                <div className="text-center">
                    <Spin size="large" />
                </div>
            )}
        </div>
    );
};

export default EditEstimationQuestion;
