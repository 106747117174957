import React, { useEffect, useState } from "react";
import { Button, Typography, Form, Select, Input, notification } from "antd";
import { get } from "../../../api/axios";
import { DeleteOutlined } from "@ant-design/icons";
import { onChangeTableHelper } from "../../control-sections/helper/TableHelpers";
import TableComponent from "../../control-sections/helper/TableComponent";

const Estimates = () => {
    const { Title, Paragraph } = Typography;
    const [form] = Form.useForm();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingSearch, setLoadingSearch] = useState(false);

    const [total, setTotal] = useState(1);

    const columns = [
        {
            title: " Id",
            dataIndex: "id",
            sorter: (a, b) => a.id - b.id,
        },
        {
            title: " user name",
            render: ({ user }) => (
                <>
                    {user.name ?? "username"}
                </>
            )
        },
        {
            title: " user phone",
            render: ({ user }) => (
                <>
                    {user.phone ?? "phone number"}
                </>
            )
        },
        {
            title: "Specifications",
            render: ({ request_specifications }) => (
                <div className="border rounded text-start">
                    {
                        request_specifications.map(item => (
                            <div className="border-bottom px-3 pt-2">
                                <Paragraph>Question:  {item['question'].name_en} </Paragraph>
                                <Paragraph>Answer:  {item['answer'].name_en} </Paragraph>
                            </div>
                        ))
                    }
                </div>
            )
        },
        {
            title: "User Estimate ",
            dataIndex: "user_estimate"
        },
        {
            title: "Estimation Price ",
            dataIndex: "estimation_price"
        },
        {
            title: "Comment",
            dataIndex: "comment"
        },
        {
            title: "Rate Stars",
            dataIndex: "rate_stars"
        },
        {
            title: "Created at",
            render: ({ created_at }) => (
                <>
                    {created_at}
                </>
            ),
        },
    ];

    // if (
    //     checkPermission("update-invest-request")
    // ) {
    //     columns.push({
    //         title: "Actions",
    //         dataIndex: "id",
    //         render: (id) => (
    //             <div>
    //                 {checkPermission("update-invest-request") ? (
    //                     <Button className="btn btn-outline-warning btn-edit btn-sm p-0 me-3">
    //                         <Link
    //                             className="color-inherit text-decoration-none py-1 px-2 d-block"
    //                             to={`/estimates/edit/${id}`}
    //                         >
    //                             <EditOutlined />
    //                         </Link>
    //                     </Button>
    //                 ) : (
    //                     <></>
    //                 )}
    //             </div>
    //         ),
    //     });
    // }

    useEffect(() => {
        get("estimates").then((res) => {
            setData(res.data.data);
            setTotal(res.data.meta.total);
            setLoading(false);
        });
    }, []);


    const [filterData, setFilterData] = useState({
        filter_key: "",
        filter_value: "",
    });

    const [sorter, setSorter] = useState({
        sort_by: "",
        order: "",
    });

    const handleFilterKey = (e) => {
        setFilterData({ ...filterData, filter_key: e });
    };

    const handleFilterVal = (e) => {
        setFilterData({ ...filterData, [e.target.id]: e.target.value });
    };

    const onFilter = (values) => {
        setLoadingSearch(true);


        get(
            `estimates?filter_key=${filterData.filter_key}&filter_value=${filterData.filter_value
            }${sorter.sort_by !== "" && sorter.sort_by !== undefined ? `&sort_by=${sorter.sort_by}` : ""}${sorter.order !== "" ? `&order=${sorter.order}` : ""
            }`
        )
            .then((res) => {
                setData(res.data.data);
                setTotal(res.data.meta.total);
                setLoadingSearch(false);
            })
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoadingSearch(false);
            });
    };

    const clearSearch = () => {
        form.resetFields();
        setFilterData({
            filter_key: "",
            filter_value: "",
        });
        get("estimates").then((res) => {
            setData(res.data.data);
            setTotal(res.data.meta.total);
        });
    };
    const [setSelectedIds] = useState({
        ids: []
    });

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedIds({
                ids: selectedRowKeys,
            });
        },
        getCheckboxProps: (record) => ({
            name: record.id,
        }),
    };

   
    const onChangeTable = (pagination, filters, sorter) => {
        setLoading(true);
        setSorter({
            ...sorter,
            sort_by: sorter.field,
            order: sorter.order == "descend" ? "desc" : "asc",
        });

        onChangeTableHelper('estimates', pagination, filterData, sorter)
            .then((res) => {
                setData(res.data.data);
                setTotal(res.data.meta.total);
                setLoading(false);
            })
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoading(false);
            });
    };

    return (
        <div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="row">
                        <div className="col-6 col-lg-4">
                            <Title level={2} className="text-main mb-2">
                                {" "}
                                Estimates
                                {" "}
                            </Title>
                        </div>
                    </div>
                </div>
            </div>

            <div className="grid-margin stretch-card wrapper-content">
                <Form onFinish={onFilter} layout="vertical" form={form}>
                    <div className="row">
                        <div className="col-12 col-lg-4">
                            <Form.Item
                                label="Search By"
                                name="filter_key"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <Select
                                    className="w-100"
                                    showSearch
                                    placeholder="select test "
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label ?? "")
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                    onChange={handleFilterKey}
                                    options={[
                                        {
                                            value: "user_name",
                                            label: "User name",
                                        },
                                        {
                                            value: "user_email",
                                            label: "User email",
                                        },
                                        {
                                            value: "user_phone",
                                            label: "phone number",
                                        },
                                        {
                                            value: "id",
                                            label: "Id",
                                        },
                                    ]}
                                />
                            </Form.Item>
                        </div>
                        <div className="col-12 col-lg-6">
                            <Form.Item
                                label="Search"
                                name="filter_value"
                                onChange={handleFilterVal}
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <Input placeholder="Type to search..." />
                            </Form.Item>
                        </div>
                        <div className="col-12 col-lg-2 d-flex align-items-end">
                            <Form.Item className="w-100">
                                <div className="row">
                                    <div className="col-8">
                                        <Button
                                            block
                                            loading={loadingSearch}
                                            type="primary"
                                            htmlType="submit"
                                        >
                                            {loadingSearch ? "Searching" : "Search"}
                                        </Button>
                                    </div>
                                    <div className="col-4">
                                        <Button
                                            onClick={clearSearch}
                                            className="flex-center"
                                            danger
                                            type="primary"
                                        >
                                            <DeleteOutlined />
                                        </Button>
                                    </div>
                                </div>
                            </Form.Item>
                        </div>
                    </div>
                </Form>
                <TableComponent onChangeTable={onChangeTable} loading={loading} columns={columns} data={data} total={total}
                    rowSelection={{
                        type: "checkbox",
                        ...rowSelection,
                    }} />
            </div>
        </div>
    );
};

export default Estimates;
