import React, { useEffect, useState } from "react";
import { Input, Typography, Button, Spin, Form, Switch, Upload, Select, notification } from "antd";
import { get, postFromData, put } from "../../../api/axios";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { ArrowLeftOutlined, UploadOutlined } from "@ant-design/icons";

const { Title } = Typography;

const EditCompany = () => {
    const navigate = useNavigate();
    let { id } = useParams();

    const [fromLoading, setFromLoading] = useState(false);

    const [inputsValue, setInputsValue] = useState({});
    const [fileList, setFileList] = useState([
        {
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        },
    ]);
    const [dataTypes, setDataTypes] = useState([]);
    const [optionTypes, setOptionTypes] = useState([]);

    useEffect(() => {
        setOptionTypes(
            dataTypes.map((d) => ({
                value: d.value,
                label: d.name,
            }))
        );
    }, [dataTypes]);

    useEffect(() => {
        get("get-all-company-types").then((res) => {
            setDataTypes(res.data);
        });
    }, []);

    const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
    }

    useEffect(() => {
        get(`companies/${id}`).then((res) => {
            setInputsValue(res.data);
            setFromLoading(true);
            setFileList(
                [
                    {
                        uid: '-1',
                        name: 'image.png',
                        status: 'done',
                        url: res.data.logo,
                        // url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
                    },
                ]
            )
        });
    }, [id]);


    const handleBeforeUpload = (e) => {
        setLoading('uploading')
        postFromData('/upload-image', { 'image': e, 'store_path': 'companies', 'old_path': inputsValue.logo_path }).then(res => {
            setInputsValue({ ...inputsValue, logo_path: res.data })
            setLoading('');
        })
        .catch((err) => {
            notification.error({
                message: "Error!",
                description: err.response.data.message,
            });
            setLoading(false);
        })
    }


    const [loading, setLoading] = useState(false);

    const onFinish = (values) => {
        setLoading('loading');
        put(`companies/${id}`, { ...values, 'logo': inputsValue.logo_path })
            .then((res) => {
                notification.success({message:"Modified successfully"});
                setLoading('');
                navigate("/companies");
            })
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoading(false);
            });
    };

    return (
        <div>
            <Title level={2} className="text-main mb-2">
                <Link className="text-dark me-3" to="/companies">
                    <ArrowLeftOutlined />
                </Link>
                Edit Company
            </Title>
            {fromLoading ? (
                <Form
                    className="wrapper-content"
                    onFinish={onFinish}
                    labelCol={{
                        span: 7,
                    }}
                    wrapperCol={{
                        span: 15,
                    }}
                    initialValues={{
                        order: inputsValue.order,
                        type: inputsValue.type,
                        is_active: inputsValue.is_active,
                    }}
                    autoComplete="off"
                >
                    <div className="row">
                        <div className="col-lg-6">
                            <Form.Item
                                label="Order "
                                name="order"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <Input type="number" placeholder="Type..." />
                            </Form.Item>
                        </div>
                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item
                                label="Type"
                                name="type"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <Select
                                    // className="w-100"
                                    // filterOption={(input, option) =>
                                    //     (option?.label ?? "")
                                    //         .toLowerCase()
                                    //         .includes(input.toLowerCase())
                                    // }
                                    // placeholder="Select"
                                    allowClear
                                    showSearch
                                    optionFilterProp="children"
                                    className="w-100"
                                    filterOption={(input, option) =>
                                        (option?.label ?? "")
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                    options={optionTypes}
                                />
                            </Form.Item>
                        </div>
                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item label="Active " name="is_active">
                                <Switch defaultChecked={inputsValue.is_active} />
                            </Form.Item>
                        </div>

                        <div className='col-lg-6'>
                            <Form.Item
                                label='logo'
                            >
                                <Upload
                                    beforeUpload={handleBeforeUpload}
                                    listType="picture"
                                    maxCount={1}
                                    defaultFileList={fileList}
                                    onPreview={onPreview}

                                >
                                    <Button icon={<UploadOutlined />}> Click to upload an image </Button>
                                </Upload>
                            </Form.Item>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 offset-lg-4">
                            <Form.Item
                                wrapperCol={{
                                    offset: 1,
                                    span: 16,
                                }}
                            >
                                <Button
                                    loading={loading == 'loading' ? true : loading == 'uploading' ? true : false}
                                    size="large"
                                    block
                                    type="primary"
                                    htmlType="submit"
                                >
                                    {loading == 'loading' ? "Updating..." : loading == 'uploading' ? 'Uploading' : "Update"}
                                </Button>
                            </Form.Item>
                        </div>
                    </div>
                </Form>
            ) : (
                <div className="text-center">
                    <Spin size="large" />
                </div>
            )}
        </div>
    );
};

export default EditCompany;
