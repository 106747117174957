import React, { useEffect, useState } from "react";
import {
  Input,
  Typography,
  Button,
  Spin,
  Form,
  Select,
  Switch,
  notification,
} from "antd";
import { get, put } from "../../../api/axios";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { ArrowLeftOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";

const { Title } = Typography;

const EditVariationCategories = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const [form] = Form.useForm();

  const [fromLoading, setFromLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const [inputsValue, setInputsValue] = useState({});

  useEffect(() => {
    get(`variation-categories/${id}`).then(res => {
      setInputsValue(res.data.data);
      console.log("res.data.data", res.data.data);
      setFromLoading(true);
    });
  }, [id]);

  const handleSwitch = (status, name) => {
    setInputsValue({ ...inputsValue, [name]: status });
  };

  const [dataPackages, setDataPackages] = useState([]);
  const [optionPackages, setOptionApplicablePropertyTypes] = useState([]);

  useEffect(() => {
    setOptionApplicablePropertyTypes(
      dataPackages.map(d => ({
        value: d.id,
        label: d.name_en,
      }))
    );
  }, [dataPackages]);

  useEffect(() => {
    get("get-all-packages").then(res => {
      setDataPackages(res.data);
    });
  }, []);

  const onFinish = values => {
    setLoading("loading");

    put(`variation-categories/${id}`, { ...values })
      .then(res => {
        notification.success({ message: "Modified successfully" });
        setLoading("");
        navigate("/variation-categories");
      })
      .catch(err => {
        notification.error({
          message: "Error!",
          description: err.response.data.message,
        });
        setLoading(false);
      });
  };

  return (
    <div>
      <Title level={2} className="text-main mb-2">
        <Link className="text-dark me-3" to="/variation-categories">
          <ArrowLeftOutlined />
          Edit Variation Category
        </Link>
      </Title>
      {fromLoading ? (
        <Form
          className="wrapper-content"
          onFinish={onFinish}
          form={form}
          labelCol={{
            span: 7,
          }}
          wrapperCol={{
            span: 15,
          }}
          initialValues={{
            name_ar: inputsValue.name_ar,
            name_en: inputsValue.name_en,
            description_ar: inputsValue.description_ar,
            description_en: inputsValue.description_en,
            order: inputsValue.order,
            is_active: inputsValue.is_active,
            message_ar: inputsValue.message_ar,
            message_en: inputsValue.message_en,
            is_custom: inputsValue.is_custom,
            package_ids: inputsValue.packages_ids,
          }}
        >
          <div className="row">
            <div className="col-lg-6">
              <Form.Item
                label="Name Ar "
                name="name_ar"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <Input placeholder="Type..." />
              </Form.Item>
            </div>
            <div className="col-lg-6">
              <Form.Item
                label="Name En "
                name="name_en"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <Input placeholder="Type..." />
              </Form.Item>
            </div>
            <div className="col-lg-6">
              <Form.Item label="Desc Ar " name="description_ar">
                <TextArea placeholder="Type..."></TextArea>
              </Form.Item>
            </div>
            <div className="col-lg-6">
              <Form.Item label="Desc En " name="description_en">
                <TextArea placeholder="Type..."></TextArea>
              </Form.Item>
            </div>
            <div className="col-lg-6">
              <Form.Item
                label="Order"
                name="order"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <Input type="number" placeholder="Type..." />
              </Form.Item>
            </div>

            {/* Item */}
            <div className="col-lg-6">
              <Form.Item
                label="Package"
                name="package_ids"
                // rules={[
                //     {
                //         required: true,
                //         message: "This field is required!",
                //     },
                // ]}
              >
                <Select
                  mode="multiple"
                  showSearch
                  optionFilterProp="children"
                  className="w-100"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  placeholder="Select"
                  options={optionPackages}
                />
              </Form.Item>
            </div>

            <div className="col-lg-6">
              <Form.Item label="Active" name="is_active">
                <Switch
                  defaultChecked={inputsValue.is_active}
                  onChange={status => handleSwitch(status, "is_active")}
                />
              </Form.Item>
            </div>
            {/* Item */}
            <div className="col-lg-6">
              <Form.Item label="Custom" name="is_custom">
                <Select
                  className="w-100"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  placeholder="Select"
                  options={[
                    {
                      label: "Yes",
                      value: true,
                    },
                    {
                      label: "No",
                      value: false,
                    },
                  ]}
                  onChange={v =>
                    setInputsValue({ ...inputsValue, is_custom: v })
                  }
                />
              </Form.Item>
            </div>

            {inputsValue.is_custom && (
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-6">
                    <Form.Item label="Message Ar " name="message_ar">
                      <TextArea placeholder="Type..."></TextArea>
                    </Form.Item>
                  </div>
                  <div className="col-lg-6">
                    <Form.Item label="Message En " name="message_en">
                      <TextArea placeholder="Type..."></TextArea>
                    </Form.Item>
                  </div>
                </div>
              </div>
            )}

            <div className="col-lg-4 offset-lg-4">
              <Form.Item
                wrapperCol={{
                  offset: 1,
                  span: 16,
                }}
              >
                <Button
                  loading={
                    loading == "loading"
                      ? true
                      : loading == "uploading"
                      ? true
                      : false
                  }
                  size="large"
                  block
                  type="primary"
                  htmlType="submit"
                >
                  {loading == "loading"
                    ? "Updating..."
                    : loading == "uploading"
                    ? "Uploading"
                    : "Update"}
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      ) : (
        <div className="text-center">
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

export default EditVariationCategories;
