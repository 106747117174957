import axios from "axios";
export const token = localStorage.getItem("tokenAdmin");

// Create Axios instance
const axiosApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL, // Set base URL
  headers: {
    'Accept': "application/json",
    "api-key": process.env.REACT_APP_API_KEY,
    'device': "admin",
    'lang': "en"
  },
});


// Set authorization header with token from local storage
axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;

// Intercept responses to handle errors
axiosApi.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.message == "Request failed with status code 401" ||
      error.message == "Request failed with status code 403" ||
      error.message == "Unauthenticated"
      || error.response.data.error == "ApiTokenInvalid" || error.response.data.message == "Token is Invalid"
    ) {
      // Redirect to login page and clear local storage
      window.location.href = "/login";
      localStorage.removeItem("tokenAdmin");
      localStorage.removeItem("permissionsAdmin");
      localStorage.removeItem("loggedInAdmin");
      localStorage.removeItem("authDataAdmin");
    }
    return Promise.reject(error);
  }
);

// Exported functions for making HTTP requests

export async function get(url) {
  return await axiosApi
    .get(url)
    .then((response) => response.data)
    .catch((err) => err);
}

export async function post(url, data) {
  return axiosApi.post(url, data).then((res) => res.data);
}

export async function postFromData(url, data) {
  const formData = new FormData();
  const dataList = data;
  for (let item in dataList) {
    formData.append(item, dataList[item]);
  }
  return axiosApi.post(url, formData).then((res) => res.data);
}

export async function put(url, data) {
  return axiosApi.put(url, data).then((response) => response.data);
}

export async function putFromData(url, data) {
  const formData = new FormData();
  const dataList = data;
  for (let item in dataList) {
    formData.append(item, dataList[item]);
  }
  return axiosApi.put(url, formData).then((res) => res.data);
}

export async function del(url) {
  return await axiosApi.delete(url).then((response) => {
    return response.data;
  });
}

export default axiosApi;
