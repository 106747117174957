import React, {useEffect, useState} from 'react';
import { DefaultLatLng, GoogleMapLibrary, GoogleMapsAPI } from "../../../api/client-config";
import { Autocomplete, GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api";
import { getGeocode } from "use-places-autocomplete";
import { Form } from "antd";

const MapComponent = ({ initialPosition, onMarkerPositionChange }) => {
    const [mapPosition, setMapPosition] = useState(DefaultLatLng); // Default position of the map
    const [markerPosition, setMarkerPosition] = useState(DefaultLatLng); // Marker position
    const [mapRef, setMapRef] = useState(null); // Google Map reference
    const [acRef, setAcRef] = useState(null); // Autocomplete reference
    const [currentAddress, setCurrentAddress] = useState(""); // Address for current location

    const { isLoaded } = useLoadScript({
        id: "map",
        googleMapsApiKey: GoogleMapsAPI,
        libraries: GoogleMapLibrary
    });
    useEffect(() => {
        if (initialPosition) {
            setMapPosition(initialPosition);
            setMarkerPosition(initialPosition);
        }
    }, []);
    const handleOnLoad = (map) => {
        setMapRef(map); // Set map reference
    };

    const handleOnLoadAC = (autocomplete) => {
        setAcRef(autocomplete); // Set autocomplete reference
    };

    const handleOnCenterChanged = () => {
        if (mapRef) {
            const centerMap = mapRef.getCenter();
            const centerLat = centerMap?.lat();
            const centerLng = centerMap?.lng();
            if (centerLat && centerLng) {
                const newMarkerPosition = {
                    lat: centerLat,
                    lng: centerLng
                };
                setMarkerPosition(newMarkerPosition);
                onMarkerPositionChange(newMarkerPosition); // Pass position to parent component
            }
        }
    };

    const handleOnDragEnd = async () => {
        if (!mapRef) return;
        const centerMap = mapRef.getCenter();
        try {
            const res = await getGeocode({ location: centerMap });
            const streetAddress = res.find(({ types }) => types.includes("street_address"));
            setCurrentAddress(streetAddress?.formatted_address ?? res[0].formatted_address);
        } catch (error) {
            console.error("Geocode error:", error); // Handle any errors during geocoding
        }
    };

    const handleOnACSelected = () => {
        if (acRef) {
            const selectedPlace = acRef.getPlace();
            const selectedLat = selectedPlace.geometry?.location?.lat();
            const selectedLng = selectedPlace.geometry?.location?.lng();
            if (selectedLat && selectedLng) {
                const newMapPosition = { lat: selectedLat, lng: selectedLng };
                setMapPosition(newMapPosition); // Update map position when a place is selected
                setMarkerPosition(newMapPosition); // Update marker position
            }
        }
    };

    return (
        <div className="col-lg-12 mt-5">
            <Form.Item label="Marker Position Search" className="mb-0" />
            {isLoaded && (
                <>
                    <Autocomplete
                        onLoad={handleOnLoadAC}
                        onPlaceChanged={handleOnACSelected}
                    >
                        <input
                            type="text"
                            style={{
                                boxSizing: `border-box`,
                                border: `1px solid transparent`,
                                width: `100%`,
                                height: `50px`,
                                padding: `0 12px`,
                                marginBottom: `15px`,
                                borderRadius: `3px`,
                                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                                fontSize: `14px`,
                                outline: `none`,
                                textOverflow: `ellipsis`
                            }}
                        />
                    </Autocomplete>
                    <p>{currentAddress}</p>
                    <GoogleMap
                        onLoad={handleOnLoad}
                        mapContainerStyle={{
                            width: "100%",
                            height: "400px"
                        }}
                        center={mapPosition}
                        zoom={13}
                        onCenterChanged={handleOnCenterChanged}
                        onDragEnd={handleOnDragEnd}
                    >
                        <MarkerF position={markerPosition} />
                    </GoogleMap>
                </>
            )}
        </div>
    );
};

export default MapComponent;
