import React, { useEffect, useState } from "react";
import { Input, Typography, Button, Select, Form, Switch, notification } from "antd";
import { get, post } from "../../../api/axios";
import { Link, useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import ReactQuill from "react-quill";

const { Title } = Typography;

const AddAgentProjectQuestion = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [inputsValue, setInputsValue] = useState({is_active: true,});
    const [optionProject, setOptionProject] = useState([]);


    const handleSwitch = (status, name) => {
        setInputsValue({ ...inputsValue, [name]: status });
    };
    useEffect(() => {
        get("get-agent-projects").then(res => {
            let projects = [];
            res.data.map(project => {
                projects.push({ label: project.name_en, value: project.id});
                return projects;
            });
            setOptionProject(projects);
        });
    }, []);
    const onFinish = (values) => {
        setLoading('loading');
        post("agent-project-questions",
            {
                ...values,
                ...inputsValue,
            })
            .then((res) => {
                notification.success({ message: "Added successfully" });
                setLoading('');
                navigate("/agent-project-questions");
            })
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoading(false);
            });
    };


    return (
        <div>
            <Title level={2} className="text-main mb-2">
                <Link className="text-dark me-3" to="/agent-project-questions">
                    <ArrowLeftOutlined />
                </Link>
                Add Project Question
            </Title>
            <Form
                className="wrapper-content"
                onFinish={onFinish}
                form={form}
                labelCol={{
                    span: 7,
                }}
                wrapperCol={{
                    span: 15,
                }}
                initialValues={{
                    answers: [{}],
                    'type': 'choice'
                }}
            >
                <div className="row">
                    <div className="col-lg-6">
                        <Form.Item
                            label='Question Ar'
                            className='w-100 mb-1'
                            name='question_ar'
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required!",
                                },
                            ]}
                        >
                            <Input className='w-100' placeholder="Type..." />
                        </Form.Item>
                    </div>
                    <div className="col-lg-6">
                        <Form.Item
                            label='Question En'
                            className='w-100 mb-1'
                            name='question_en'
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required!",
                                },
                            ]}
                        >
                            <Input className='w-100' placeholder="Type..." />
                        </Form.Item>
                    </div>
                    <div className="col-lg-6">
                        <Form.Item
                            label='Answer Ar'
                            className='w-100 mb-1'
                            name='answer_ar'
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required!",
                                },
                            ]}
                        >
                            <ReactQuill className='w-100' placeholder="Type..." />
                        </Form.Item>
                    </div>
                    <div className="col-lg-6">
                        <Form.Item
                            label='Answer En'
                            className='w-100 mb-1'
                            name='answer_en'
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required!",
                                },
                            ]}
                        >
                            <ReactQuill className='w-100' placeholder="Type..." />
                        </Form.Item>
                    </div>
                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item
                            label="Project"
                            name="agent_project_id"
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required!",
                                },
                            ]}
                        >
                            <Select
                                allowClear
                                showSearch
                                optionFilterProp="children"
                                className="w-100"
                                filterOption={(input, option) =>
                                    (option?.label ?? "")
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                                placeholder="Select"
                                options={optionProject}
                            />
                        </Form.Item>
                    </div>
                    <div className="col-lg-6">
                        <Form.Item
                            label="Order"
                            name="order"
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required!",
                                },
                            ]}
                        >
                            <Input type="number" placeholder="Type..." />
                        </Form.Item>
                    </div>
                    <div className="col-lg-6">
                        <Form.Item label="Active" name="is_active">
                            <Switch
                                defaultChecked={inputsValue.is_active}
                                onChange={(status) => handleSwitch(status, "is_active")}
                            />
                        </Form.Item>
                    </div>

                    <div className="col-lg-4 offset-lg-4">
                        <Form.Item
                            wrapperCol={{
                                offset: 1,
                                span: 16,
                            }}
                        >
                            <Button
                                loading={loading == 'loading' ? true : loading == 'uploading' ? true : false}
                                size="large"
                                block
                                type="primary"
                                htmlType="submit"
                            >
                                {loading == 'loading' ? "Updating..." : loading == 'uploading' ? 'Uploading' : "Update"}
                            </Button>
                        </Form.Item>
                    </div>
                </div>
            </Form>
        </div>
    );
};

export default AddAgentProjectQuestion;
