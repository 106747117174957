import React, { useEffect, useState } from "react";
import {
  Input,
  Typography,
  Button,
  Form,
  Switch,
  notification,
  Select,
} from "antd";
import { get, post } from "../../../api/axios";
import { Link, useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";

const { Title } = Typography;


const AddCampaignPackage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [inputsValue, setInputsValue] = useState({
    is_active: true,
    is_most_popular: true,
  });

  const [dataType, setDataType] = useState([]);
  const [optionType, setOptionType] = useState([]);

  useEffect(() => {
    setOptionType(
      dataType.map((d) => ({
        value: d.id,
        label: d.name_en,
      }))
    );
  }, [dataType]);

  useEffect(() => {
    get("get-agent-projects").then((res) => {
      setDataType(res.data);
    });
  }, []);

  const handleSwitch = (status, name) => {
    setInputsValue({ ...inputsValue, [name]: status });
  };



  const onFinish = values => {
    setLoading(true);

    post("campaign-packages", {
      ...values,
      ...inputsValue,
    })
      .then(res => {
        notification.success({ message: "Added successfully" });
        setLoading(false);
        navigate("/campaign-packages");
      })
      .catch(err => {
        notification.error({
          message: "Error!",
          description: err.response.data.message,
        });
        setLoading(false);
      });
  };

  return (
    <div>
      <Title level={2} className="text-main mb-2">
        <Link className="text-dark me-3" to="/campaign-packages">
          <ArrowLeftOutlined />
        </Link>
        Add Campaign Package
      </Title>
      <Form
        className="wrapper-content"
        onFinish={onFinish}
        labelCol={{
          span: 9,
        }}
        wrapperCol={{
          span: 14,
        }}

      >
        <div className="row">
          {/* Item */}
          <div className="col-lg-6">
            <Form.Item
              label="project"
              name="project_id"
              rules={[
                {
                  required: true,
                  message: "This field is required!",
                },
              ]}
            >
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                className="w-100"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                placeholder="Select"
                options={optionType}
              />
            </Form.Item>
          </div>
          {/* Item */}
          <div className="col-lg-6">
            <Form.Item
              label="Cost"
              name="cost"
              rules={[
                {
                  required: true,
                  message: "This field is required!",
                },
              ]}
            >
              <Input type="number" />
            </Form.Item>
          </div>
          {/* Item */}
          <div className="col-lg-6">
            <Form.Item
              label="Min Leads"
              name="min_leads"
              rules={[
                {
                  required: true,
                  message: "This field is required!",
                },
              ]}
            >
              <Input type="number" />
            </Form.Item>
          </div>
          {/* Item */}
          <div className="col-lg-6">
            <Form.Item
                label="Max Leads"
                name="max_leads"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
            >
              <Input type="number" />
            </Form.Item>
          </div>
          {/* Item */}
          <div className="col-lg-6">
            <Form.Item
              label="Possibility Of Sale"
              name="possibility_of_sale"
              rules={[
                {
                  required: true,
                  message: "This field is required!",
                },
              ]}
            >
              <Input type="number" />
            </Form.Item>
          </div>

          {/* Item */}
          <div className="col-lg-6">
            <Form.Item
              label="Order"
              name="order"
              rules={[
                {
                  required: true,
                  message: "This field is required!",
                },
              ]}
            >
              <Input type="number" />
            </Form.Item>
          </div>
          {/* Item */}
          <div className="col-lg-6">
            <Form.Item label="Active " name="is_active">
              <Switch
                defaultChecked
                onChange={status => handleSwitch(status, "is_active")}
              />
            </Form.Item>
          </div>
          {/* Item */}
          <div className="col-lg-6">
            <Form.Item label="Most Popular " name="is_most_popular">
              <Switch
                defaultChecked
                onChange={status => handleSwitch(status, "is_most_popular")}
              />
            </Form.Item>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-4 offset-lg-4">
            <Form.Item
              wrapperCol={{
                offset: 1,
                span: 16,
              }}
            >
              <Button
                loading={loading}
                size="large"
                block
                type="primary"
                htmlType="submit"
              >
                {loading ? "Saving..." : "Save"}
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default AddCampaignPackage;
