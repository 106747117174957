import React, { useEffect, useState } from "react";
import {
  Input,
  Typography,
  Button,
  Form,
  notification,
  Select,
} from "antd";
import { get, post } from "../../../api/axios";
import { Link, useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";

const { Title } = Typography;


const AddAgentPrivateProperty = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const [inputsValue] = useState({
  });
  const [dataAgent, setDatAgent] = useState([]);
  const [optionAgent, setOptionAgent] = useState([]);

  useEffect(() => {
    setOptionAgent(
      dataAgent.map((d) => ({
        value: d.id,
        label: d.name,
      }))
    );
  }, [dataAgent]);


  useEffect(() => {
    get("get-agent-users?is_registered=true").then((res) => {
      setDatAgent(res.data);
    });
  }, []);

  const onFinish = values => {
    setLoading(true);

    post("agent-private-properties", {
      ...values,
      ...inputsValue,
    })
      .then(res => {
        notification.success({ message: "Added successfully" });
        setLoading(false);
        navigate("/agent-private-properties");
      })
      .catch(err => {
        notification.error({
          message: "Error!",
          description: err.response.data.message,
        });
        setLoading(false);
      });
  };

  return (
    <div>
      <Title level={2} className="text-main mb-2">
        <Link className="text-dark me-3" to="/agent-private-properties">
          <ArrowLeftOutlined />
        </Link>
        Add private property
      </Title>
      <Form
        form={form}
        className="wrapper-content"
        onFinish={onFinish}
        labelCol={{
          span: 9,
        }}
        wrapperCol={{
          span: 14,
        }}

      >
        <div className="row">

          {/* Item */}
          <div className="col-lg-6">
            <Form.Item
              label="Agent"
              name="agent_id"
              rules={[
                {
                  required: true,
                  message: "This field is required!",
                },
              ]}
            >
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                className="w-100"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                placeholder="Select"
                options={optionAgent}
              />
            </Form.Item>
          </div>



          <div className="col-lg-6">
            <Form.Item
              label="title"
              name="title"
            >
              <Input placeholder="Type..." />
            </Form.Item>
          </div>
          <div className="col-lg-6">
            <Form.Item
              label="price"
              name="price"
            >
              <Input placeholder="Type..." />
            </Form.Item>
          </div>

        </div>

        <div className="row">
          <div className="col-lg-4 offset-lg-4">
            <Form.Item
              wrapperCol={{
                offset: 1,
                span: 16,
              }}
            >
              <Button
                loading={loading}
                size="large"
                block
                type="primary"
                htmlType="submit"
              >
                {loading ? "Saving..." : "Save"}
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default AddAgentPrivateProperty;
