import React, { useEffect, useState } from "react";
import {
  Input,
  Typography,
  Button,
  Spin,
  Form,
  notification,
  Select,
  DatePicker, InputNumber,
} from "antd";
import { get, put } from "../../../api/axios";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { ArrowLeftOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import dayjs from "dayjs";

const { Title } = Typography;

const EditAgentReservation = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const [form] = Form.useForm();

  const [fromLoading, setFromLoading] = useState(false);

  const [inputsValue, setInputsValue] = useState({});

  const [dataAgentCustomer, setDataAgentCustomer] = useState([]);
  const [optionAgentCustomer, setOptionAgentCustomer] = useState([]);

  const [dataAgentProjectUnit, setDataProjectUnit] = useState([]);
  const [optionProjectUnit, setOptionProjectUnit] = useState([]);

  useEffect(() => {
    get(`agent-reservations/${id}`).then(res => {
      setInputsValue(res.data);

      get(`get-agent-users/${res.data.agent.id}/customers`).then(response => {
        setDataAgentCustomer(response.data);
      });
      get(`get-agent-projects/${res.data.agent_project_id}/units`).then(response => {
        setDataProjectUnit(response.data);
      });

      setFromLoading(true);
    });
  }, [id]);

  const [loading, setLoading] = useState(false);



  const [dataAgent, setDataAgent] = useState([]);
  const [optionAgent, setOptionAgent] = useState([]);

  useEffect(() => {
    setOptionAgent(
      dataAgent.map((d) => ({
        value: d.id,
        label: d.id +' - '+ d.name + ' - '+ d.country_code + d.phone_number,
      }))
    );
  }, [dataAgent]);




  useEffect(() => {
    setOptionAgentCustomer(
      dataAgentCustomer.map((d) => ({
        value: d.id,
        label: d.id +' - '+ d.name + ' - '+ d.country_code + d.phone_number,
      }))
    );
  }, [dataAgentCustomer]);



  const [dataProject, setDataProject] = useState([]);
  const [optionProject, setOptionProject] = useState([]);

  useEffect(() => {
    setOptionProject(
      dataProject.map((d) => ({
        value: d.id,
        label: d.name_en,
      }))
    );
  }, [dataProject]);



  useEffect(() => {
    setOptionProjectUnit(
      dataAgentProjectUnit.map((d) => ({
        value: d.id,
        label: d.number,
      }))
    );
  }, [dataAgentProjectUnit]);



  useEffect(() => {
    get("get-agent-users?is_registered=true").then((res) => {
      setDataAgent(res.data);
    });
    get("get-agent-projects").then((res) => {
      setDataProject(res.data);
    });

  }, []);



  const handleAgentId = value => {
    form.setFieldsValue({
      agent_customer_id: '',
    });
    get(`get-agent-users/${value}/customers`).then(response => {
      setDataAgentCustomer(response.data);
    });

  }

  const handleProjectId = value => {
    form.setFieldsValue({
      agent_project_unit_id: '',
    });
    get(`get-agent-projects/${value}/units`).then(response => {
      setDataProjectUnit(response.data);
    });

  }


  const onFinish = values => {
    setLoading(true);
    const newValues = {
      ...values,
      reservation_date: values.reservation_date.format("YYYY-MM-DD"),
      contract_date: values.contract_date ? values.contract_date.format("YYYY-MM-DD") : null,
      aqarmap_receiving_commission_date: values.aqarmap_receiving_commission_date !== null ?
          values.aqarmap_receiving_commission_date.format("YYYY-MM-DD") : null,
      aqarmap_sent_commission_date: values.aqarmap_sent_commission_date !== null ?
          values.aqarmap_sent_commission_date.format("YYYY-MM-DD") : null,
    };

    put(`agent-reservations/${id}`, newValues)
      .then(res => {
        notification.success({ message: "Modified successfully" });
        setLoading(false);
        navigate("/agent-reservations");
      })
      .catch(err => {
        notification.error({
          message: "Error!",
          description: err.response.data.message,
        });
        setLoading(false);
      });
  };

  return (
    <div>
      <Title level={2} className="text-main mb-2">
        <Link className="text-dark me-3" to="/agent-reservations">
          <ArrowLeftOutlined />
        </Link>
        Edit Agent Reservation
      </Title>
      {fromLoading ? (
        <Form
          className="wrapper-content"
          form={form}
          layout={"vertical"}
          onFinish={onFinish}
          labelCol={{
            span: 18,
          }}
          wrapperCol={{
            span: 19,
          }}
          initialValues={{
            agent_id: inputsValue.agent_id,
            agent_customer_id: inputsValue.agent_customer_id,
            agent_project_id: inputsValue.agent_project_id,
            agent_project_unit_id: inputsValue.agent_project_unit_id,
            initial_price: inputsValue.initial_price,
            final_price: inputsValue.final_price,
            commission_rate: inputsValue.commission_rate,
            total_commission: inputsValue.total_commission,
            agent_commission: inputsValue.agent_commission,
            reservation_date: dayjs(inputsValue.reservation_date),
            contract_date: inputsValue.aqarmap_receiving_commission_date !== null ?
                dayjs(inputsValue.contract_date) : null,
            aqarmap_receiving_commission_date: inputsValue.aqarmap_receiving_commission_date !== null ?
                dayjs(inputsValue.aqarmap_receiving_commission_date) : null,
            aqarmap_sent_commission_date: inputsValue.aqarmap_sent_commission_date !== null ?
                dayjs(inputsValue.aqarmap_sent_commission_date) : null,
            notes: inputsValue.notes,
            status: inputsValue.status,
          }}
        >
          <div className="row">
            {/* Item */}
            <div className="col-lg-6">
              <Form.Item
                label="Agent"
                name="agent_id"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  className="w-100"
                  onChange={handleAgentId}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  placeholder="Select"
                  options={optionAgent}
                />
              </Form.Item>
            </div>
            {/* Item */}
            <div className="col-lg-6">
              <Form.Item
                label="Agent Customer"
                name="agent_customer_id"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  className="w-100"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  placeholder="Select"
                  options={optionAgentCustomer}
                />
              </Form.Item>
            </div>
            {/* Item */}
            <div className="col-lg-6">
              <Form.Item
                label="Agent Project"
                name="agent_project_id"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  className="w-100"
                  onChange={handleProjectId}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  placeholder="Select"
                  options={optionProject}
                />
              </Form.Item>
            </div>
            {/* Item */}
            <div className="col-lg-6">
              <Form.Item
                label="Agent Project Unit"
                name="agent_project_unit_id"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  className="w-100"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  placeholder="Select"
                  options={optionProjectUnit}
                />
              </Form.Item>
            </div>
            {/* Item */}
            <div className="col-lg-6">
              <Form.Item
                label="Initial Price"
                name="initial_price"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <InputNumber
                    style={{ width: '100%' }}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                />
              </Form.Item>
            </div>
            {/* Item */}
            <div className="col-lg-6">
              <Form.Item
                label="Final Price"
                name="final_price"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <InputNumber
                    style={{ width: '100%' }}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                />
              </Form.Item>
            </div>
            {/* Item */}
            <div className="col-lg-6">
              <Form.Item
                label="Commission Rate"
                name="commission_rate"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <Input type="number" />
              </Form.Item>
            </div>
            {/* Item */}
            <div className="col-lg-6">
              <Form.Item
                label="Total Commission"
                name="total_commission"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <InputNumber
                    style={{ width: '100%' }}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                />
              </Form.Item>
            </div>
            {/* Item */}
            <div className="col-lg-6">
              <Form.Item
                label="Agent Commission"
                name="agent_commission"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <InputNumber
                    style={{ width: '100%' }}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                />
              </Form.Item>
            </div>
            {/* Item */}
            <div className="col-lg-6">
              <Form.Item
                label="Reservation Date"
                name="reservation_date"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <DatePicker placeholder="" className="w-100" />
              </Form.Item>
            </div>
            {/* Item */}
            <div className="col-lg-6">
              <Form.Item
                label="Contract Date"
                name="contract_date"
              >
                <DatePicker placeholder="" className="w-100" />
              </Form.Item>
            </div>
            {/* Item */}
            <div className="col-lg-6">
              <Form.Item
                label="Aqarmap Receiving Commission Date"
                name="aqarmap_receiving_commission_date"
              >
                <DatePicker placeholder="" className="w-100" />
              </Form.Item>
            </div>
            {/* Item */}
            <div className="col-lg-6">
              <Form.Item
                label="Aqarmap Sent Commission Date"
                name="aqarmap_sent_commission_date"
              >
                <DatePicker placeholder="" className="w-100" />
              </Form.Item>
            </div>
            {/* Item */}
            <div className="col-lg-6">
              <Form.Item
                label="Notes"
                name="notes"
              >
                <TextArea placeholder="Type..." ></TextArea>
              </Form.Item>
            </div>

            {/* Item */}
            <div className="col-lg-6">
              <Form.Item
                label="Status"
                name="status"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  className="w-100"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  placeholder="Select"
                  options={[
                    {
                      value: "reserved",
                      label: "Reserved"
                    },
                    {
                      value: "empty",
                      label: "Empty"
                    },
                    {
                      value: "delivered",
                      label: "Delivered"
                    },
                  ]}
                />
              </Form.Item>
            </div>

          </div>

          <div className="row">
            <div className="col-lg-4 offset-lg-4">
              <Form.Item
                wrapperCol={{
                  offset: 1,
                  span: 16,
                }}
              >
                <Button
                  loading={loading}
                  size="large"
                  block
                  type="primary"
                  htmlType="submit"
                >
                  {loading ? "Updating..." : "Update"}
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      ) : (
        <div className="text-center">
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

export default EditAgentReservation;
