import {Button, Divider, Form, Input, InputNumber, Select, Switch} from "antd";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import ReactQuill from "react-quill";
import React from "react";

const QuestionsComponent = () => {
   return (
       <Form.List name="questions">
           {(fields, {add, remove}) => (
               <>
                   {fields.map(({key, name, ...restField}) => (

                       <div
                           key={`q-${key}`}
                       >
                           <div className="d-flex">
                               <Form.Item
                                   label='Question Ar'
                                   className='w-100 mb-1'
                                   {...restField}
                                   name={[name, 'question_ar']}

                                   rules={[
                                       {
                                           required: true,
                                           message: "This field is required!",
                                       },
                                   ]}
                               >
                                   <Input className='w-100' placeholder="Type..."/>
                               </Form.Item>
                               <Form.Item
                                   label='Question En'
                                   className='w-100 mb-1'
                                   {...restField}
                                   name={[name, 'question_en']}
                                   rules={[
                                       {
                                           required: true,
                                           message: "This field is required!",
                                       },
                                   ]}
                               >
                                   <Input className='w-100' placeholder="Type..."/>
                               </Form.Item>
                           </div>
                           <div className="d-flex">
                               <Form.Item
                                   label='Answer Ar'
                                   className='w-100 mb-1'
                                   {...restField}
                                   name={[name, 'answer_ar']}

                                   rules={[
                                       {
                                           required: true,
                                           message: "This field is required!",
                                       },
                                   ]}
                               >
                                   <ReactQuill className='w-100' placeholder="Type..."/>
                               </Form.Item>
                               <Form.Item
                                   label='Answer En'
                                   className='w-100 mb-1'
                                   {...restField}
                                   name={[name, 'answer_en']}

                                   rules={[
                                       {
                                           required: true,
                                           message: "This field is required!",
                                       },
                                   ]}
                               >
                                   <ReactQuill className='w-100' placeholder="Type..."/>
                               </Form.Item>

                           </div>
                           <div className="d-flex">
                               <Form.Item
                                   label='Order'
                                   className='w-100 mb-1'
                                   {...restField}
                                   name={[name, 'order']}

                                   rules={[
                                       {
                                           required: true,
                                           message: "This field is required!",
                                       },
                                   ]}
                               >
                                   <Input type="number" className='w-100' placeholder="Type..."/>
                               </Form.Item>
                               <Form.Item
                                   label='Active'
                                   className='w-100 mb-1'
                                   {...restField}
                                   name={[name, 'is_active']}
                               >
                                   <Switch defaultChecked={true}/>
                               </Form.Item>
                           </div>
                           <MinusCircleOutlined className='ms-1' onClick={() => remove(name)}/>
                           <Divider/>
                       </div>
                   ))}
                   <div className='d-flex justify-content-end'>
                       <Button className='text-main' type="link" onClick={() => add()} icon={<PlusOutlined/>}>
                           Add Question
                       </Button>
                   </div>

               </>
           )}
       </Form.List>
   );
};
export default QuestionsComponent;
