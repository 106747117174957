import React, { useState } from "react";
import {
    Input,
    Typography,
    Button,
    Form,
    Switch,
    notification,
    ColorPicker,
    Select,
} from "antd";
import { post } from "../../../api/axios";
import { Link, useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import ReactQuill from "react-quill";

const { Title } = Typography;

const AddPackageAgent = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [inputsValue, setInputsValue] = useState({
        is_active: true,
        is_free: false,
        is_popular: false,
    });
    const [selectedTypes, setSelectedTypes] = useState([]);

    const handleSwitch = (status, name) => {
        setInputsValue({ ...inputsValue, [name]: status });
    };

    const handlePicker = (color, name) => {
        setInputsValue({ ...inputsValue, [name]: color.toHexString() });
    };

    const handleTypeChange = (value) => {
        setSelectedTypes(value);
    };

    const onFinish = (values) => {
        setLoading(true);

        post("agent-packages", {
            ...values,
            ...inputsValue,
        })
            .then((res) => {
                notification.success({ message: "Added successfully" });
                setLoading(false);
                navigate("/agent-packages");
            })
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoading(false);
            });
    };

    return (
        <div>
            <Title level={2} className="text-main mb-2">
                <Link className="text-dark me-3" to="/agent-packages">
                    <ArrowLeftOutlined />
                </Link>
                Add Agent Package
            </Title>
            <Form
                className="wrapper-content"
                onFinish={onFinish}
                labelCol={{
                    span: 9,
                }}
                wrapperCol={{
                    span: 15,
                }}
                initialValues={{
                    remember: true,
                }}
                autoComplete="off"
            >
                <div className="row">
                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item
                            label="Name Ar"
                            name="name_ar"
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required!",
                                },
                            ]}
                        >
                            <Input placeholder="Type..." />
                        </Form.Item>
                    </div>
                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item
                            label="Name En "
                            name="name_en"
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required!",
                                },
                            ]}
                        >
                            <Input placeholder="Type..." />
                        </Form.Item>
                    </div>
                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item
                            label="Description ar "
                            name="description_ar"
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required!",
                                },
                            ]}
                        >
                            <ReactQuill theme="snow" />
                        </Form.Item>
                    </div>
                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item
                            label="Description en "
                            name="description_en"
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required!",
                                },
                            ]}
                        >
                            <ReactQuill theme="snow" />
                        </Form.Item>
                    </div>
                    {/*multi select for price type*/}
                    <div className="col-lg-12">
                        <Form.Item
                            label="Type"
                            name="type"
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required!",
                                },
                            ]}
                        >
                            <Select
                                mode="multiple"
                                className="w-100"
                                showSearch
                                allowClear
                                placeholder="Select"
                                optionFilterProp="children"
                                onChange={handleTypeChange} // Handle type changes
                                filterOption={(input, option) =>
                                    (option?.label ?? "")
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                                options={[
                                    {
                                        value: "one_time",
                                        label: "One Time",
                                    },
                                    {
                                        value: "monthly",
                                        label: "Monthly",
                                    },
                                    {
                                        value: "annually",
                                        label: "Annually",
                                    },
                                ]}
                            />
                        </Form.Item>
                    </div>
                    {/* Conditional Price Inputs */}
                    {selectedTypes.includes("monthly") && (
                        <>
                            <div className="col-lg-6">
                                <Form.Item
                                    label="Price Monthly"
                                    name="price_monthly"
                                >
                                    <Input type="number" placeholder="Type..."/>
                                </Form.Item>
                            </div>
                            <div className="col-lg-6">
                                <Form.Item
                                    label="Store Monthly ID"
                                    name="store_monthly_id"
                                    rules={[
                                        {
                                            required: true,
                                            message: "This field is required!",
                                        },
                                    ]}
                                >
                                    <Input placeholder="Type..."/>
                                </Form.Item>
                            </div>
                        </>
                    )}
                    {selectedTypes.includes("annually") && (
                        <>
                            <div className="col-lg-6">
                                <Form.Item
                                    label="Price Annually"
                                    name="price_annually"
                                >
                                    <Input type="number" placeholder="Type..."/>
                                </Form.Item>
                            </div>
                            {/* Item */}
                            <div className="col-lg-6">
                                <Form.Item
                                    label="Store Annually ID"
                                    name="store_annually_id"
                                    rules={[
                                        {
                                            required: true,
                                            message: "This field is required!",
                                        },
                                    ]}
                                >
                                    <Input placeholder="Type..." />
                                </Form.Item>
                            </div>
                        </>
                    )}
                    {selectedTypes.includes("one_time") && (
                        <div className="col-lg-12">
                            <Form.Item
                                label="Price One Time"
                                name="price_one_time"
                            >
                                <Input type="number" placeholder="Type..."/>
                            </Form.Item>
                        </div>
                    )}
                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item
                            label="Order"
                            name="order"
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required!",
                                },
                            ]}
                        >
                            <Input placeholder="Type..." type="number" min={1} />
                        </Form.Item>
                    </div>
                    <div className="col-lg-6">
                        <Form.Item
                            label="Color"
                            name="color"
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required!",
                                },
                            ]}
                        >
                            <ColorPicker
                                defaultValue="#0277bd"
                                onChange={(color) =>
                                    handlePicker(color, 'color')
                                }
                                size="large"
                            />
                        </Form.Item>
                    </div>

                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item label="Popular" name="is_popular">
                            <Switch
                                defaultChecked
                                onChange={(status) => handleSwitch(status, "is_popular")}
                            />
                        </Form.Item>
                    </div>
                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item label="Active " name="is_active">
                            <Switch
                                defaultChecked
                                onChange={(status) => handleSwitch(status, "is_active")}
                            />
                        </Form.Item>
                    </div>
                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item label="Free " name="is_free">
                            <Switch
                                defaultChecked={false}
                                onChange={(status) => handleSwitch(status, "is_free")}
                            />
                        </Form.Item>
                    </div>
                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item label="Has Pro Access on Insights Map" name="is_pro">
                            <Switch
                                defaultChecked
                                onChange={(status) => handleSwitch(status, "is_pro")}
                            />
                        </Form.Item>
                    </div>

                </div>
                <div className="row">
                    <div className="col-lg-4 offset-lg-4">
                        <Form.Item
                            wrapperCol={{
                                offset: 1,
                                span: 16,
                            }}
                        >
                            <Button
                                loading={loading}
                                size="large"
                                block
                                type="primary"
                                htmlType="submit"
                            >
                                {loading ? "Saving..." : "Save"}
                            </Button>
                        </Form.Item>
                    </div>
                </div>
            </Form>
        </div>
    );
};

export default AddPackageAgent;
