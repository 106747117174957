import React, {useEffect, useState} from "react";
import { Upload, Button, notification, Progress } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { postFromData } from "../../../api/axios";

const DocumentUploadComponent = ({ onFileUpload, onFileDelete, fileList, setFileList, storagePath }) => {
    const [uploading, setUploading] = useState(false);
    const [progress, setProgress] = useState(0);
    // Update `fileList` to ensure each file has a proper URL and name
    useEffect(() => {
        setFileList((prevList) =>
            prevList.map((file, index) => ({
                ...file,
                uid: file.uid || `${index}-${new Date().getTime()}`, // Ensure each file has a unique UID
                url: file.url || `${process.env.REACT_APP_BASE_IMAGE_URL}${file.path}`, // Add base URL if missing
                path: file.path,
                name: file.name, // Set a default name if missing
            }))
        );
    }, [setFileList]);
    const handleBeforeUpload = async (file) => {
        setUploading(true);
        let simulatedProgress = 0;
        const progressInterval = setInterval(() => {
            simulatedProgress += 5; // Increase progress by 5% every interval
            setProgress(Math.min(simulatedProgress, 95)); // Cap progress at 95% to await actual completion
        }, 200);
        try {
            const response = await postFromData('/upload-file', { 'file': file, 'store_path': storagePath });
            clearInterval(progressInterval); // Clear progress interval on completion
            setProgress(100); // Set progress to 100% on success

            const filePath = response.data;
            file.url = process.env.REACT_APP_BASE_IMAGE_URL + filePath;
            file.path = filePath;
            setFileList((prev) => [...prev, {
                ...file
            }]);
            onFileUpload(filePath);
            console.log(file, fileList);

            notification.success({
                message: "Upload Successful",
                description: `${file.name} uploaded successfully!`,
            });
        } catch (error) {
            notification.error({
                message: "Error!",
                description: `File upload failed.${error}`,
            });
        } finally {
            setUploading(false);
            setProgress(0);
        }
        return false;
    };

    const handleChange = ({ fileList }) => {
        setFileList(fileList);
    };
    const handleRemove = (file) => {
        const filePath =  process.env.REACT_APP_BASE_IMAGE_URL + file.url || file.preview;
        onFileDelete(filePath, file.lang); // This will now call the correct function passed from the parent
    };
    return (
        <>
            <Upload
                fileList={fileList}
                onChange={handleChange}
                onRemove={handleRemove}
                beforeUpload={handleBeforeUpload}
                listType="text"
                maxCount={1}
                accept=".pdf,.xlsx,.xls"
            >
                <Button icon={<UploadOutlined />} loading={uploading}>Click to upload PDF/Excel</Button>
            </Upload>
            {uploading && <Progress percent={progress} />}
        </>
    );
};

export default DocumentUploadComponent;
