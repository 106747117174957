import React, { useEffect, useState } from "react";
import { Form, Modal, notification, Progress, Upload } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { postFromData } from "../../../api/axios";

const ImagesUploadComponent = ({ onImageUpload, imageList, setImageList, storagePath }) => {
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [previewTitle, setPreviewTitle] = useState("");
    const [uploading, setUploading] = useState(false);
    const [progress, setProgress] = useState({});

    // Ensure each preloaded image has a unique uid, url, and name
    useEffect(() => {
        setImageList((prevList) =>
            prevList.map((image, index) => ({
                ...image,
                uid: `${index}-${new Date().getTime()}`,
                url: `${process.env.REACT_APP_BASE_IMAGE_URL}${image.path}`,
                path: image.path || "",
                name: image.name || `Image-${index + 1}`,
            }))
        );
    }, [setImageList]);

    const handlePreview = (file) => {
        console.log("Previewing file:", file);
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf("/") + 1));
    };

    const handleChange = async ({ fileList }) => {
        // Ensure only new files are uploaded
        const newFiles = fileList.filter(
            (file) => !imageList.some((existingFile) => existingFile.path === file.path)
        );

        if (newFiles.length > 0) {
            // Upload only the new files
            await handleBeforeUpload(newFiles.map((file) => file.originFileObj || file));
            // Update the fileList with existing and new files to prevent re-uploads

            setImageList(fileList.map((file) => ({
                ...file.originFileObj || file,
            })));
        }

    };

    const handleBeforeUpload = async (files) => {
        setUploading(true);

        for (let file of files) {
            const fileUid = file.uid || `${imageList.length +1 }-${Date.now()}`;

            let simulatedProgress = 0;
            const progressInterval = setInterval(() => {
                simulatedProgress += 5;
                setProgress((prev) => ({
                    ...prev,
                    [fileUid]: Math.min(simulatedProgress, 95),
                }));
            }, 200);

            try {
                // Simulate upload
                const response = await postFromData("/upload-image", {
                    image: file,
                    store_path: storagePath,
                });

                clearInterval(progressInterval);
                setProgress((prev) => ({
                    ...prev,
                    [fileUid]: 100,
                }));

                const imagePath = response.data;
                if (imagePath) {
                    file.url = `${process.env.REACT_APP_BASE_IMAGE_URL}${imagePath}`;
                    file.uid = fileUid;
                    file.path = imagePath;
                    // setImageList((prevList) => [...prevList, file.originFileObj || file]);
                } else {
                    throw new Error("Invalid response data");
                }
            } catch (error) {
                console.error(error);
                clearInterval(progressInterval);
                setProgress((prev) => ({
                    ...prev,
                    [fileUid]: 0,
                }));

                notification.error({
                    message: "Upload Failed",
                    description: `${file.name} could not be uploaded.`,
                });
            }
        }

        setUploading(false);
    };

    const handleRemove = (file) => {
        console.log("Removing file:", file);
        console.log("Image List:", imageList);
        setImageList((prevList) => prevList.filter((item) => item.uid !== file.uid));
        notification.info({
            message: "Image Removed",
            description: `${file.name} was removed.`,
        });
    };

    return (
        <>
            <Form.Item label="images" className="mb-0" />
            <Upload
                listType="picture-card"
                fileList={imageList}
                onPreview={handlePreview}
                onChange={handleChange}
                beforeUpload={() => false} // Disable default upload behavior
                onRemove={handleRemove}
                accept=".png,.jpg,.jpeg,.svg"
                multiple
            >
                {imageList.length >= 12 ? null : (
                    <div>
                        <PlusOutlined />
                        <div>Upload</div>
                    </div>
                )}
            </Upload>
            <Modal
                open={previewOpen}
                title={previewTitle}
                footer={null}
                onCancel={() => setPreviewOpen(false)}
            >
                <img alt="preview" style={{ width: "100%" }} src={previewImage} />
            </Modal>
            {uploading &&
                Object.entries(progress).map(([uid, percent]) => (
                    <Progress key={uid} percent={percent} style={{ marginBottom: 10 }} />
                ))}
        </>
    );
};

export default ImagesUploadComponent;
