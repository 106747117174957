import React, { useEffect, useState } from "react";
import {
  Input,
  Typography,
  Button,
  Spin,
  Form,
  notification,
  Select,
} from "antd";
import { get, put } from "../../../api/axios";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { ArrowLeftOutlined } from "@ant-design/icons";

const { Title } = Typography;

const EditAgentPrivateProperty = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const [form] = Form.useForm();

  const [fromLoading, setFromLoading] = useState(false);

  const [inputsValue, setInputsValue] = useState({});

  useEffect(() => {
    get(`agent-private-properties/${id}`).then(res => {
      setInputsValue(res.data);
      setFromLoading(true);
    });
  }, [id]);
  const [loading, setLoading] = useState(false);
  const [optionAgent, setOptionAgent] = useState([]);

  useEffect(() => {
    get("get-agent-users?is_registered=true").then((res) => {
      let agents = [];
      res.data.map((d) => {
        agents.push({
          value: d.id,
          label: d.name,
        });
        return agents;
      });
      setOptionAgent(agents);
    });


  }, []);



  const onFinish = values => {
    setLoading(true);
    const newValues = {
      ...values,
      show_agent_name: values.show_agent_name ? 1 : 0,
    }

    put(`agent-private-properties/${id}`, newValues)
      .then(res => {
        notification.success({ message: "Modified successfully" });
        setLoading(false);
        navigate("/agent-private-properties");
      })
      .catch(err => {
        notification.error({
          message: "Error!",
          description: err.response.data.message,
        });
        setLoading(false);
      });
  };

  return (
    <div>
      <Title level={2} className="text-main mb-2">
        <Link className="text-dark me-3" to="/agent-private-properties">
          <ArrowLeftOutlined />
        </Link>
        Edit Agent private property
      </Title>
      {fromLoading ? (
        <Form
          className="wrapper-content"
          form={form}
          onFinish={onFinish}
          labelCol={{
            span: 9,
          }}
          wrapperCol={{
            span: 14,
          }}
          initialValues={{
            agent_id: inputsValue.agent.id,
            title: inputsValue.title,
            price: inputsValue.price,

          }}
        >
          <div className="row">

            {/* Item */}
            <div className="col-lg-6">
              <Form.Item
                label="Agent"
                name="agent_id"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  className="w-100"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  placeholder="Select"
                  options={optionAgent}
                />
              </Form.Item>
            </div>



            <div className="col-lg-6">
              <Form.Item
                label="title"
                name="title"
              >
                <Input placeholder="Type..." />
              </Form.Item>
            </div>
            <div className="col-lg-6">
              <Form.Item
                label="price"
                name="price"
              >
                <Input placeholder="Type..." />
              </Form.Item>
            </div>

          </div>

          <div className="row">
            <div className="col-lg-4 offset-lg-4">
              <Form.Item
                wrapperCol={{
                  offset: 1,
                  span: 16,
                }}
              >
                <Button
                  loading={loading}
                  size="large"
                  block
                  type="primary"
                  htmlType="submit"
                >
                  {loading ? "Updating..." : "Update"}
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      ) : (
        <div className="text-center">
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

export default EditAgentPrivateProperty;
