import React, { useEffect, useState } from "react";
import { Input, Typography, Button, Spin, Form, Switch, ColorPicker, notification, Select } from "antd";
import { get, post } from "../../../api/axios";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const { Title } = Typography;

const AgentSettings = () => {
    const [fromLoading, setFromLoading] = useState(false);
    const navigate = useNavigate();

    const [inputsValue, setInputsValue] = useState([]);
    const [initValues, setInitValues] = useState({});
    const [optionPackages, setOptionPackages] = useState([]);
    const [tagsData, setTagsData] = useState({});

    useEffect(() => {
        get("agent-settings").then((res) => {
            const initValuesNew = {};
            const tags = {};

            res.data.forEach((item) => {
                if (item.key === "private_property_package") {
                    initValuesNew[item.key] = item.value.split(",").map(Number);
                } else if (item.type === "tags") {
                    tags[item.key] = item.value ? JSON.parse(item.value) : [];
                    initValuesNew[item.key] = item.value ? JSON.parse(item.value) : [];
                } else {
                    initValuesNew[item.key] = item.value;
                }
            });

            setInitValues(initValuesNew);
            setTagsData(tags);
            setInputsValue(res.data);

            get("get-agent-packages").then((res) => {
                const options = res.data.map((item) => ({
                    value: item.id,
                    label: item.name_en,
                }));
                setOptionPackages(options);
            });

            setFromLoading(true);
        });
    }, []);

    const [loading, setLoading] = useState(false);

    const handlePicker = (color, name) => {
        initValues[name] = color.toHexString();
    };

    const handleTagChange = (key, value) => {
        setTagsData((prev) => ({
            ...prev,
            [key]: value,
        }));
    };

    const onFinish = (values) => {
        setLoading(true);

        const newArr = [];
        Object.keys(values).forEach((key) => {
            const newObj = { key, value: values[key] };

            // If the field is a tags field, send it as an array
            if (key in tagsData) {
                newObj.value = JSON.stringify(tagsData[key]); // Convert array to JSON string
            }

            newArr.push(newObj);
        });

        post("agent-settings", { settings: newArr })
            .then(() => {
                notification.success({ message: "Modified successfully" });
                setLoading(false);
                navigate("/agent-settings");
            })
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response?.data?.message || "Unknown error",
                });
                setLoading(false);
            });
    };

    return (
        <div>
            <Title level={2} className="text-main mb-2">
                Agent Settings
            </Title>
            {fromLoading ? (
                <Form
                    className="wrapper-content"
                    onFinish={onFinish}
                    layout="vertical"
                    initialValues={initValues}
                    autoComplete="off"
                >
                    <div className="row">
                        {inputsValue.map((item) => (
                            <div className="col-lg-6" key={item.key}>
                                <Form.Item
                                    label={item.name}
                                    name={item.key}
                                    rules={[
                                        {
                                            required: item.type !== "checkbox",
                                            message: "This field is required!",
                                        },
                                    ]}
                                    extra={
                                        item.key === "starting_zoom_level" || item.key === "apply_max_at_zoom_level"
                                            ? "Zoom level must be between 7 and 14"
                                            : " "
                                    }
                                >
                                    {item.type === "checkbox" ? (
                                        <Switch defaultChecked={item.value} />
                                    ) : item.type === "textarea" ? (
                                        <ReactQuill theme="snow" value={item.value} />
                                    ) : item.type === "color" ? (
                                        <ColorPicker
                                            defaultValue="#0277bd"
                                            onChange={(color) => handlePicker(color, item.key)}
                                            size="large"
                                        />
                                    ) : item.type === "tags" ? (
                                        <Select
                                            mode="tags"
                                            style={{ width: "100%" }}
                                            placeholder="Add tags"
                                            value={tagsData[item.key] || []} // Default to empty array if undefined
                                            onChange={(value) => handleTagChange(item.key, value)}
                                        />
                                    ) : item.type === "select" ? (
                                        <Select
                                            mode="multiple"
                                            allowClear
                                            showSearch
                                            className="w-100"
                                            filterOption={(input, option) =>
                                                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                                            }
                                            placeholder="Select"
                                            options={optionPackages}
                                        />
                                    ) : (
                                        <Input placeholder="Type..." />
                                    )}
                                </Form.Item>
                            </div>
                        ))}
                    </div>
                    <div className="row">
                        <div className="col-lg-4 offset-lg-4">
                            <Form.Item
                                wrapperCol={{
                                    offset: 1,
                                    span: 16,
                                }}
                            >
                                <Button
                                    loading={loading}
                                    size="large"
                                    block
                                    type="primary"
                                    htmlType="submit"
                                >
                                    {loading ? "Saving..." : "Save"}
                                </Button>
                            </Form.Item>
                        </div>
                    </div>
                </Form>
            ) : (
                <div className="text-center">
                    <Spin size="large" />
                </div>
            )}
        </div>
    );
};

export default AgentSettings;
