import React, {useEffect, useRef, useState} from "react";
import {
  Input,
  Typography,
  Button,
  Form,
  notification,
  Select,
} from "antd";
import { get, post } from "../../../api/axios";
import { Link, useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import IntlTelInput from "intl-tel-input/reactWithUtils";
import "intl-tel-input/build/css/intlTelInput.css";
import 'react-intl-tel-input/dist/main.css';

const { Title } = Typography;


const AddAgentCustomer = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const intlTelInputRef = useRef(null); // Reference for IntlTelInput
  const [form] = Form.useForm();

  const [inputsValue] = useState({
    is_active: true,
    is_most_popular: true,
  });

  const [dataType, setDataType] = useState([]);
  const [optionType, setOptionType] = useState([]);

  useEffect(() => {
    setOptionType(
      dataType.map((d) => ({
        value: d.id,
        label: d.id +' - '+ d.name + ' - '+ d.country_code + d.phone_number,
      }))
    );
  }, [dataType]);

  const [dataCustomer, setDataCustomer] = useState([]);
  const [optionCustomer, setOptionCustomer] = useState([]);
  const [optionCustomerStatus, setOptionCustomerStatus] = useState([]);

  useEffect(() => {
    const filterData = dataCustomer.filter(item => item.type == 'customer_source')
    const filterData2 = dataCustomer.filter(item => item.type == 'customer_status')
    setOptionCustomer(
      filterData.map((d) => ({
        value: d.id,
        label: d.name_en,
      }))
    );
    setOptionCustomerStatus(
      filterData2.map((d) => ({
        value: d.id,
        label: d.name_en,
      }))
    );
  }, [dataCustomer]);

  useEffect(() => {
    get("get-agent-users?is_registered=true").then((res) => {
      setDataType(res.data);
    });
    get("/agent-dropdowns").then((res) => {
      setDataCustomer(res.data.data);
    });
  }, []);

  const onFinish = values => {
    // Ensure the reference is available
    if (!intlTelInputRef.current) {
      notification.error({ message: "Phone input not initialized." });
      return;
    }

    const intlTelInputInstance = intlTelInputRef.current.getInstance();

    // Ensure the instance is available
    if (!intlTelInputInstance) {
      notification.error({ message: "Phone input instance not found." });
      return;
    }

    const isValid = intlTelInputInstance.isValidNumber();

    if (!isValid) {
      notification.error({ message: "Invalid phone number!" });
      form.setFields([{ name: "phone_number", errors: ["Invalid phone number"] }]);
      return false;
    }

    const selectedCountryDialCode = intlTelInputInstance.getSelectedCountryData().dialCode;
    const validNumber = intlTelInputInstance.getNumber();

    const newValues = {
      ...values,
      ...inputsValue,
      phone_number: validNumber.replace("+" + selectedCountryDialCode, ""),
      country_code: selectedCountryDialCode,
    };
    setLoading(true);

    post("agent-customers", newValues)
      .then(res => {
        notification.success({ message: "Added successfully" });
        setLoading(false);
        navigate("/agent-customers");
      })
      .catch(err => {
        notification.error({
          message: "Error!",
          description: err.response.data.message,
        });
        setLoading(false);
      });
  };

  return (
    <div>
      <Title level={2} className="text-main mb-2">
        <Link className="text-dark me-3" to="/agent-customers">
          <ArrowLeftOutlined />
        </Link>
        Add Agent Customer
      </Title>
      <Form
        className="wrapper-content"
        onFinish={onFinish}
        labelCol={{
          span: 9,
        }}
        wrapperCol={{
          span: 14,
        }}

      >
        <div className="row">
          {/* Item */}
          <div className="col-lg-6">
            <Form.Item
              label="Agent"
              name="agent_id"
              rules={[
                {
                  required: true,
                  message: "This field is required!",
                },
              ]}
            >
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                className="w-100"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                placeholder="Select"
                options={optionType}
              />
            </Form.Item>
          </div>
          {/* Item */}
          <div className="col-lg-6">
            <Form.Item
              label="Agent"
              name="customer_source_id"
              rules={[
                {
                  required: true,
                  message: "This field is required!",
                },
              ]}
            >
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                className="w-100"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                placeholder="Select"
                options={optionCustomer}
              />
            </Form.Item>
          </div>
          {/* Item */}
          <div className="col-lg-6">
            <Form.Item
              label="Agent"
              name="customer_status_id"
              rules={[
                {
                  required: true,
                  message: "This field is required!",
                },
              ]}
            >
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                className="w-100"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                placeholder="Select"
                options={optionCustomerStatus}
              />
            </Form.Item>
          </div>
          {/* Item */}
          <div className="col-lg-6">
            <Form.Item
              label="Phone"
              name="phone_number"
            >
              <IntlTelInput
                  ref={intlTelInputRef}
                  preferredCountries={['sa', 'eg']}
                  separateDialCode={true}
                  utilsScript={"https://cdn.jsdelivr.net/npm/intl-tel-input@17/build/js/utils.js"}
              />
            </Form.Item>
          </div>

          {/* Item */}
          <div className="col-lg-6">
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "This field is required!",
                },
              ]}
            >
              <Input placeholder="Type..." />
            </Form.Item>
          </div>
          {/* Item */}
          <div className="col-lg-6">
            <Form.Item
              label="Notes"
              name="notes"
            >
              <Input placeholder="Type..." />
            </Form.Item>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-4 offset-lg-4">
            <Form.Item
              wrapperCol={{
                offset: 1,
                span: 16,
              }}
            >
              <Button
                loading={loading}
                size="large"
                block
                type="primary"
                htmlType="submit"
              >
                {loading ? "Saving..." : "Save"}
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default AddAgentCustomer;
