import React, { useEffect, useState } from "react";
import { Input, Typography, Button, Spin, Form, Switch, notification, Select } from "antd";
import { get, put } from "../../../api/axios";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { ArrowLeftOutlined } from "@ant-design/icons";
import ImageUploadComponent from "../../control-sections/helper/ImageUploadComponent";

const { Title } = Typography;

const EditSliderAgent = () => {
    const navigate = useNavigate();
    let { id } = useParams();
    const [fromLoading, setFromLoading] = useState(false);
    const [inputsValue, setInputsValue] = useState({});

    const [smallImageAr, setSmallImageAr] = useState([]);
    const [largeImageAr, setLargeImageAr] = useState([]);
    const [smallImageEn, setSmallImageEn] = useState([]);
    const [largeImageEn, setLargeImageEn] = useState([]);
    useEffect(() => {
        get(`onboarding-sections/${id}`).then((res) => {

            setSmallImageAr(res.data.data.small_image_ar ? [{
                url: res.data.data.small_image_ar,
                path: res.data.data.small_image_path_ar,
                name: res.data.data?.small_image_path_ar?.substring(res.data.data?.small_image_path_ar?.lastIndexOf('/') + 1)
            }] : []);
            setLargeImageAr(res.data.data.large_image_ar ? [{
                url: res.data.data.large_image_ar,
                path: res.data.data.large_image_path_ar,
                name: res.data.data?.large_image_path_ar?.substring(res.data.data?.large_image_path_ar?.lastIndexOf('/') + 1)
            }] : []);
            setSmallImageEn(res.data.data.small_image_en ? [{
                url: res.data.data.small_image_en,
                path: res.data.data.small_image_path_en,
                name: res.data.data?.small_image_path_en?.substring(res.data.data?.small_image_path_en?.lastIndexOf('/') + 1)
            }] : []);
            setLargeImageEn(res.data.data.large_image_en ? [{
                url: res.data.data.large_image_en,
                path: res.data.data.large_image_path_en,
                name: res.data.data?.large_image_path_en?.substring(res.data.data?.large_image_path_en?.lastIndexOf('/') + 1)
            }] : []);
            setInputsValue({
                ...res.data.data,
                small_image_ar: res.data.data.small_image_path_ar,
                large_image_ar: res.data.data.large_image_path_ar,
                small_image_en: res.data.data.small_image_path_en,
                large_image_en: res.data.data.large_image_path_en,
                type:res.data.data.type ,
                has_info_btn: res.data.data.has_info_btn,
                info_label_ar: res.data.data.info_label_ar,
                info_label_en: res.data.data.info_label_en,
                info_url_ar: res.data.data.info_url_ar,
                info_url_en: res.data.data.info_url_en
            });
            setFromLoading(true);
        });
    }, [id]);
    const [loading, setLoading] = useState('');
    const handleSwitch = (status, name) => {
        setInputsValue({ ...inputsValue, [name]: status });
    };
    const handleFileUpload = (filePath, name) => {
        console.log(filePath);
        setInputsValue((prev) => ({ ...prev, [name]: filePath }));
    };
    const handleDeleteFile = (filePath, name) => {
        // Remove the file path from inputsValue
        setInputsValue((prev) => ({
            ...prev,
            [name]: null,  // Set the value of the specific field (name) to null
        }));
        switch (name) {
            case 'small_image_ar':
                setSmallImageAr([]);
                break;
            case 'large_image_ar':
                setLargeImageAr([]);
                break;
            case 'small_image_en':
                setSmallImageEn([]);
                break;
            case 'large_image_en':
                setLargeImageEn([]);
                break;
            default:
                console.log(name);
        }
    };

    const handleType = (e) => {
        setInputsValue({ ...inputsValue, type: e })
    };
    const onFinish = (values) => {
        setLoading('loading');
        console.log({ ...values, ...inputsValue });
        if (!inputsValue.small_image_ar) {
            notification.error({ message: "Error!", description: "Small image ar is required" });
            setLoading(false);
            return;
        }
        if (!inputsValue.large_image_ar) {
            notification.error({ message: "Error!", description: "Large image ar is required" });
            setLoading(false);
            return;
        }
        if (!inputsValue.small_image_en) {
            notification.error({ message: "Error!", description: "Small image en is required" });
            setLoading(false);
            return;
        }
        if (!inputsValue.large_image_en) {
            notification.error({ message: "Error!", description: "Large image en is required" });
            setLoading(false);
            return;
        }

        put(`onboarding-sections/${id}`, {
            ...values,
            'small_image_ar':inputsValue.small_image_ar,
            'large_image_ar':inputsValue.large_image_ar,
            'small_image_en':inputsValue.small_image_en,
            'large_image_en':inputsValue.large_image_en,
            'is_active': inputsValue.is_active ? 1 : 0,
            'has_info_btn': inputsValue.has_info_btn ? 1 : 0,

        })
            .then((res) => {
                notification.success({ message: "Modified successfully" });
                setLoading('');
                navigate("/sliders-agent");
            })
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoading(false);
            });
    };

    return (
        <div>
            <Title level={2} className="text-main mb-2">
                <Link className="text-dark me-3" to="/sliders-agent">
                    <ArrowLeftOutlined />
                </Link>
                Edit Agent Slider
            </Title>
            {fromLoading ? (
                <Form
                    className="wrapper-content"
                    onFinish={onFinish}
                    labelCol={{
                        span: 7,
                    }}
                    wrapperCol={{
                        span: 15,
                    }}
                    initialValues={{
                        order: inputsValue.order,
                        is_active: inputsValue.is_active,
                        type: inputsValue.type,
                        button_text_en: inputsValue.button_text_en,
                        button_text_ar: inputsValue.button_text_ar,
                        url_en: inputsValue.url_en,
                        url_ar: inputsValue.url_ar,
                        info_label_ar: inputsValue.info_label_ar,
                        info_label_en: inputsValue.info_label_en,
                        info_url_ar: inputsValue.info_url_ar,
                        info_url_en: inputsValue.info_url_en
                    }}
                >
                    <div className="row">
                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item
                                label='Small image ar'
                                name='small_image_ar'
                            >
                                <ImageUploadComponent
                                    onImageUpload={(imagePath) => handleFileUpload(imagePath, 'small_image_ar')}
                                    storagePath={'onboarding-sections'}
                                    image={smallImageAr}
                                    setImage={setSmallImageAr}
                                    onImageDelete={(image) => handleDeleteFile(image, 'small_image_ar')}
                                />
                            </Form.Item>
                        </div>
                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item
                                label='Small image en'
                                name='small_image_en'
                            >
                                <ImageUploadComponent
                                    onImageUpload={(imagePath) => handleFileUpload(imagePath, 'small_image_en')}
                                    storagePath={'onboarding-sections'}
                                    image={smallImageEn}
                                    setImage={setSmallImageEn}
                                    onImageDelete={(image) => handleDeleteFile(image, 'small_image_en')}
                                />
                            </Form.Item>
                        </div>
                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item
                                label='Large image ar'
                                name='large_image_ar'
                            >
                                <ImageUploadComponent
                                    onImageUpload={(imagePath) => handleFileUpload(imagePath, 'large_image_ar')}
                                    storagePath={'onboarding-sections'}
                                    image={largeImageAr}
                                    setImage={setLargeImageAr}
                                    onImageDelete={(image) => handleDeleteFile(image, 'large_image_ar')}
                                />
                            </Form.Item>
                        </div>
                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item
                                label='Large image en'
                                name='large_image_en'
                            >
                                <ImageUploadComponent
                                    onImageUpload={(imagePath) => handleFileUpload(imagePath, 'large_image_en')}
                                    storagePath={'onboarding-sections'}
                                    image={largeImageEn}
                                    setImage={setLargeImageEn}
                                    onImageDelete={(image) => handleDeleteFile(image, 'large_image_en')}
                                />
                            </Form.Item>
                        </div>

                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item
                                label="Order "
                                name="order"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <Input type="number" />
                            </Form.Item>
                        </div>

                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item label="Active " name="is_active">
                                <Switch
                                    defaultChecked
                                    onChange={(status) => handleSwitch(status, "is_active")}
                                />
                            </Form.Item>
                        </div>

                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item
                                label="Type"
                                name="type"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <Select
                                    allowClear
                                    showSearch
                                    optionFilterProp="children"
                                    className="w-100"
                                    filterOption={(input, option) =>
                                        (option?.label ?? "")
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                    placeholder="Select"
                                    onChange={handleType}
                                    options={[
                                        { value: 'investor', label: "Investor" },
                                        { value: 'agent', label: "Agent" },
                                        { value: 'url', label: "Url" },
                                    ]}
                                />
                            </Form.Item>
                        </div>

                        {
                        (inputsValue.type == 'investor' ||  inputsValue.type == 'agent') && <>
                            {/* Item */}
                            <div className="col-lg-6">
                                <Form.Item label="Has Info Button " name="has_info_btn">
                                    <Switch
                                        defaultChecked={inputsValue.has_info_btn}
                                        onChange={(status) => handleSwitch(status, "has_info_btn")}
                                    />
                                </Form.Item>
                            </div>
                            {
                                inputsValue.has_info_btn && <>
                                    {/* Item */}
                                    <div className="col-lg-6">
                                        <Form.Item
                                            label="Info URL en"
                                            name="info_url_en"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "This field is required!",
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Type..." />
                                        </Form.Item>
                                    </div>
                                    {/* Item */}
                                    <div className="col-lg-6">
                                        <Form.Item
                                            label="Info URL ar"
                                            name="info_url_ar"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "This field is required!",
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Type..." />
                                        </Form.Item>
                                    </div>
                                    {/* Item */}
                                    <div className="col-lg-6">
                                        <Form.Item
                                            label="Info label en"
                                            name="info_label_en"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "This field is required!",
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Type..." />
                                        </Form.Item>
                                    </div>
                                    {/* Item */}
                                    <div className="col-lg-6">
                                        <Form.Item
                                            label="Info label ar"
                                            name="info_label_ar"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "This field is required!",
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Type..." />
                                        </Form.Item>
                                    </div>
                                </>
                            }
                        </>
                    }
                    {
                        inputsValue.type == 'url' && <>
                            {/* Item */}
                            <div className="col-lg-6">
                                <Form.Item
                                    label="Url ar"
                                    name="url_ar"
                                    rules={[
                                        {
                                            required: true,
                                            message: "This field is required!",
                                        },
                                    ]}
                                >
                                    <Input placeholder="Type..." />
                                </Form.Item>
                            </div>
                            {/* Item */}
                            <div className="col-lg-6">
                                <Form.Item
                                    label="Url en"
                                    name="url_en"
                                    rules={[
                                        {
                                            required: true,
                                            message: "This field is required!",
                                        },
                                    ]}
                                >
                                    <Input placeholder="Type..." />
                                </Form.Item>
                            </div>
                            {/* Item */}
                            <div className="col-lg-6">
                                <Form.Item
                                    label="Button text ar"
                                    name="button_text_ar"
                                    rules={[
                                        {
                                            required: true,
                                            message: "This field is required!",
                                        },
                                    ]}
                                >
                                    <Input placeholder="Type..." />
                                </Form.Item>
                            </div>
                            {/* Item */}
                            <div className="col-lg-6">
                                <Form.Item
                                    label="Button text en"
                                    name="button_text_en"
                                    rules={[
                                        {
                                            required: true,
                                            message: "This field is required!",
                                        },
                                    ]}
                                >
                                    <Input placeholder="Type..." />
                                </Form.Item>
                            </div>

                        </>
                    }

                    </div>


                    <div className="row">
                        <div className="col-lg-4 offset-lg-4">
                            <Form.Item
                                wrapperCol={{
                                    offset: 1,
                                    span: 16,
                                }}
                            >
                                <Button
                                    loading={loading == 'loading' ? true : loading == 'uploading' ? true : false}
                                    size="large"
                                    block
                                    type="primary"
                                    htmlType="submit"
                                >
                                    {loading == 'loading' ? "Updating..." : loading == 'uploading' ? 'Uploading' : "Update"}
                                </Button>
                            </Form.Item>
                        </div>
                    </div>
                </Form>
            ) : (
                <div className="text-center">
                    <Spin size="large" />
                </div>
            )}
        </div>
    );
};

export default EditSliderAgent;