import React, { useEffect, useState, useRef } from "react";
import {
  Input,
  Typography,
  Button,
  Spin,
  Form,
  notification,
} from "antd";
import { get, put } from "../../../api/axios";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { ArrowLeftOutlined } from "@ant-design/icons";
import IntlTelInput from "intl-tel-input/reactWithUtils";
import "intl-tel-input/build/css/intlTelInput.css";
import 'react-intl-tel-input/dist/main.css';

const { Title } = Typography;

const EditAgentAccountManager = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const intlTelInputRef = useRef(null); // Reference for IntlTelInput
  const [fromLoading, setFromLoading] = useState(false);
  const [inputsValue, setInputsValue] = useState({});
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    get(`agent-account-managers/${id}`).then((res) => {
      const fullPhoneNumber = `+${res.data.country_code}${res.data.phone_number}`;
      setInputsValue({
        name: res.data.name,
        name_ar: res.data.name_ar,
        email: res.data.email,
        phone_number: fullPhoneNumber,
        country_code: res.data.country_code
      });
      setFromLoading(true);
    });
  }, [id, form]);

  useEffect(() => {
    form.setFieldsValue({
      name: inputsValue.name,
      name_ar: inputsValue.name_ar,
      email: inputsValue.email,
      phone_number: inputsValue.phone_number,
      country_code: inputsValue.country_code
    });
  }, [inputsValue, form]);

  const onFinish = (values) => {
    const intlTelInputInstance = intlTelInputRef.current.getInstance();
    const isValid = intlTelInputInstance.isValidNumber();

    if (!isValid) {
      notification.error({ message: "Invalid phone number!" });
      form.setFields([{ name: "phone_number", errors: ["Invalid phone number"] }]);
      return false;
    }

    const selectedCountryDialCode = intlTelInputInstance.getSelectedCountryData().dialCode;
    const validNumber = intlTelInputInstance.getNumber();

    const newValues = {
      ...values,
      phone_number: validNumber.replace("+" + selectedCountryDialCode, ""),
      country_code: selectedCountryDialCode,
    };

    setLoading(true);

    put(`agent-account-managers/${id}`, newValues)
        .then(() => {
          notification.success({ message: "Modified successfully" });
          setLoading(false);
          navigate("/agent-account-managers");
        })
        .catch((err) => {
          notification.error({
            message: "Error!",
            description: err.response?.data?.message,
          });
          setLoading(false);
        });
  };

  return (
      <div>
        <Title level={2} className="text-main mb-2">
          <Link className="text-dark me-3" to="/agent-account-managers">
            <ArrowLeftOutlined />
          </Link>
          Edit Agent Account Manager
        </Title>
        {fromLoading ? (
            <Form
                form={form}
                className="wrapper-content"
                onFinish={onFinish}
                labelCol={{ span: 9 }}
                wrapperCol={{ span: 14 }}
                initialValues={inputsValue}
            >
              <div className="row">
                <div className="col-lg-6">
                  <Form.Item
                      label="Name"
                      name="name"
                      rules={[{ required: true, message: "This field is required!" }]}
                  >
                    <Input placeholder="Type..." />
                  </Form.Item>
                </div>
                <div className="col-lg-6">
                  <Form.Item
                      label="Name (Arabic)"
                      name="name_ar"
                      rules={[{ required: true, message: "This field is required!" }]}
                  >
                    <Input placeholder="Type..." />
                  </Form.Item>
                </div>
                <div className="col-lg-6">
                  <Form.Item
                      label="Email"
                      name="email"
                      rules={[{ required: true, message: "This field is required!" }]}
                  >
                    <Input placeholder="Type..." />
                  </Form.Item>
                </div>
                <div className="col-lg-6">
                  <Form.Item
                      label="Phone Number"
                      name="phone_number"
                      rules={[{ required: true, message: "This field is required!" }]}
                  >
                    <IntlTelInput
                        initialValue={inputsValue.phone_number}
                        ref={intlTelInputRef}
                        preferredCountries={['sa', 'eg']}
                        separateDialCode={true}
                        utilsScript={"https://cdn.jsdelivr.net/npm/intl-tel-input@17/build/js/utils.js"}
                    />
                  </Form.Item>
                </div>
                <div className="col-lg-6">
                  <Form.Item
                      label="Password"
                      name="password"
                  >
                    <Input placeholder="Type..." />
                  </Form.Item>
                </div>
              </div>
              <Form.Item name="country_code" hidden>
                <Input type="hidden" />
              </Form.Item>
              <div className="row">
                <div className="col-lg-4 offset-lg-4">
                  <Form.Item
                      wrapperCol={{ offset: 1, span: 16 }}
                  >
                    <Button
                        loading={loading}
                        size="large"
                        block
                        type="primary"
                        htmlType="submit"
                    >
                      {loading ? "Updating..." : "Update"}
                    </Button>
                  </Form.Item>
                </div>
              </div>
            </Form>
        ) : (
            <div className="text-center">
              <Spin size="large" />
            </div>
        )}
      </div>
  );
};

export default EditAgentAccountManager;
