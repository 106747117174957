import React, { useState } from "react";
import { Input, Typography, Button, Form, Switch, notification, Select } from "antd";
import { post } from "../../../api/axios";
import { Link, useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";

import 'react-quill/dist/quill.snow.css';
import ImageUploadComponent from "../../control-sections/helper/ImageUploadComponent";

const { Title } = Typography;

const AddSliderAgent = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [inputsValue, setInputsValue] = useState({
        is_active: true,
        small_image_ar: null,
        large_image_ar: null,
        small_image_en: null,
        large_image_en: null,
        type: '',
        has_info_btn: false,
    });
    const handleSwitch = (status, name) => {
        setInputsValue({ ...inputsValue, [name]: status });
    };
    const [smallImageAr, setSmallImageAr] = useState([]);
    const [largeImageAr, setLargeImageAr] = useState([]);
    const [smallImageEn, setSmallImageEn] = useState([]);
    const [largeImageEn, setLargeImageEn] = useState([]);

    const handleFileUpload = (filePath, name) => {
        setInputsValue((prev) => ({ ...prev, [name]: filePath }));
    };
    const handleDeleteFile = (filePath, name) => {
        // Remove the file path from inputsValue
        setInputsValue((prev) => ({ ...prev, [name]: null }));
    };
    const handleType = (e) => {
        setInputsValue({ ...inputsValue, type: e })
    };
    const onFinish = (values) => {
        setLoading(true);
        console.log({ ...values, ...inputsValue });
        if (!inputsValue.small_image_ar) {
            notification.error({ message: "Error!", description: "Small image ar is required" });
            setLoading(false);
            return;
        }
        if (!inputsValue.large_image_ar) {
            notification.error({ message: "Error!", description: "Large image ar is required" });
            setLoading(false);
            return;
        }
        if (!inputsValue.small_image_en) {
            notification.error({ message: "Error!", description: "Small image en is required" });
            setLoading(false);
            return;
        }
        if (!inputsValue.large_image_en) {
            notification.error({ message: "Error!", description: "Large image en is required" });
            setLoading(false);
            return;
        }

        post("onboarding-sections", {
            ...values,
            'small_image_ar':inputsValue.small_image_ar,
            'large_image_ar':inputsValue.large_image_ar,
            'small_image_en':inputsValue.small_image_en,
            'large_image_en':inputsValue.large_image_en,
            'is_active': inputsValue.is_active ? 1 : 0,
            'has_info_btn': inputsValue.has_info_btn ? 1 : 0,


        })
            .then((res) => {
                notification.success({ message: "Added successfully" });
                setLoading(false);
                navigate("/sliders-agent");
            })
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoading(false);
            });
    };

    return (
        <div>
            <Title level={2} className="text-main mb-2">
                <Link className="text-dark me-3" to="/sliders-agent">
                    <ArrowLeftOutlined />
                </Link>
                Add Agent Slider
            </Title>
            <Form
                className="wrapper-content"
                onFinish={onFinish}
                labelCol={{
                    span: 7,
                }}
                wrapperCol={{
                    span: 15,
                }}
                initialValues={{
                    remember: true,
                }}
                autoComplete="off"
            >
                <div className="row">
                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item
                            label='Small image ar'
                            name='small_image_ar'
                        >
                            <ImageUploadComponent
                                onImageUpload={(imagePath) => handleFileUpload(imagePath, 'small_image_ar')}
                                storagePath={'onboarding-sections'}
                                image={smallImageAr}
                                setImage={setSmallImageAr}
                                onImageDelete={(image) => handleDeleteFile(image, 'small_image_ar')}
                            />
                        </Form.Item>
                    </div>
                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item
                            label='Small image en'
                            name='small_image_en'
                        >
                            <ImageUploadComponent
                                onImageUpload={(imagePath) => handleFileUpload(imagePath, 'small_image_en')}
                                storagePath={'onboarding-sections'}
                                image={smallImageEn}
                                setImage={setSmallImageEn}
                                onImageDelete={(image) => handleDeleteFile(image, 'small_image_en')}
                            />
                        </Form.Item>
                    </div>
                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item
                            label='Large image ar'
                            name='large_image_ar'
                        >
                            <ImageUploadComponent
                                onImageUpload={(imagePath) => handleFileUpload(imagePath, 'large_image_ar')}
                                storagePath={'onboarding-sections'}
                                image={largeImageAr}
                                setImage={setLargeImageAr}
                                onImageDelete={(image) => handleDeleteFile(image, 'large_image_ar')}
                            />
                        </Form.Item>
                    </div>
                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item
                            label='Large image en'
                            name='large_image_en'
                        >
                            <ImageUploadComponent
                                onImageUpload={(imagePath) => handleFileUpload(imagePath, 'large_image_en')}
                                storagePath={'onboarding-sections'}
                                image={largeImageEn}
                                setImage={setLargeImageEn}
                                onImageDelete={(image) => handleDeleteFile(image, 'large_image_en')}
                            />
                        </Form.Item>
                    </div>

                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item
                            label="Order "
                            name="order"
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required!",
                                },
                            ]}
                        >
                            <Input type="number" />
                        </Form.Item>
                    </div>

                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item label="Active " name="is_active">
                            <Switch
                                defaultChecked
                                onChange={(status) => handleSwitch(status, "is_active")}
                            />
                        </Form.Item>
                    </div>

                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item
                            label="Type"
                            name="type"
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required!",
                                },
                            ]}
                        >
                            <Select
                                allowClear
                                showSearch
                                optionFilterProp="children"
                                className="w-100"
                                filterOption={(input, option) =>
                                    (option?.label ?? "")
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                                placeholder="Select"
                                onChange={handleType}
                                options={[
                                    { value: 'investor', label: "Investor" },
                                    { value: 'agent', label: "Agent" },
                                    { value: 'url', label: "Url" },
                                ]}
                            />
                        </Form.Item>
                    </div>
                    {
                        (inputsValue.type == 'investor' ||  inputsValue.type == 'agent') && <>
                            {/* Item */}
                            <div className="col-lg-6">
                                <Form.Item label="Has Info Button " name="has_info_btn">
                                    <Switch
                                        defaultChecked={false}
                                        onChange={(status) => handleSwitch(status, "has_info_btn")}
                                    />
                                </Form.Item>
                            </div>
                            {
                                inputsValue.has_info_btn && <>
                                    {/* Item */}
                                    <div className="col-lg-6">
                                        <Form.Item
                                            label="Info URL en"
                                            name="info_url_en"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "This field is required!",
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Type..." />
                                        </Form.Item>
                                    </div>
                                    {/* Item */}
                                    <div className="col-lg-6">
                                        <Form.Item
                                            label="Info URL ar"
                                            name="info_url_ar"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "This field is required!",
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Type..." />
                                        </Form.Item>
                                    </div>
                                    {/* Item */}
                                    <div className="col-lg-6">
                                        <Form.Item
                                            label="Info label en"
                                            name="info_label_en"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "This field is required!",
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Type..." />
                                        </Form.Item>
                                    </div>
                                    {/* Item */}
                                    <div className="col-lg-6">
                                        <Form.Item
                                            label="Info label ar"
                                            name="info_label_ar"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "This field is required!",
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Type..." />
                                        </Form.Item>
                                    </div>
                                </>
                            }
                        </>
                    }
                    {
                        inputsValue.type == 'url' && <>
                            {/* Item */}
                            <div className="col-lg-6">
                                <Form.Item
                                    label="Url ar"
                                    name="url_ar"
                                    rules={[
                                        {
                                            required: true,
                                            message: "This field is required!",
                                        },
                                    ]}
                                >
                                    <Input placeholder="Type..." />
                                </Form.Item>
                            </div>
                            {/* Item */}
                            <div className="col-lg-6">
                                <Form.Item
                                    label="Url en"
                                    name="url_en"
                                    rules={[
                                        {
                                            required: true,
                                            message: "This field is required!",
                                        },
                                    ]}
                                >
                                    <Input placeholder="Type..." />
                                </Form.Item>
                            </div>
                            {/* Item */}
                            <div className="col-lg-6">
                                <Form.Item
                                    label="Button text ar"
                                    name="button_text_ar"
                                    rules={[
                                        {
                                            required: true,
                                            message: "This field is required!",
                                        },
                                    ]}
                                >
                                    <Input placeholder="Type..." />
                                </Form.Item>
                            </div>
                            {/* Item */}
                            <div className="col-lg-6">
                                <Form.Item
                                    label="Button text en"
                                    name="button_text_en"
                                    rules={[
                                        {
                                            required: true,
                                            message: "This field is required!",
                                        },
                                    ]}
                                >
                                    <Input placeholder="Type..." />
                                </Form.Item>
                            </div>

                        </>
                    }

                </div>
                <div className="row">
                    <div className="col-lg-4 offset-lg-4">
                        <Form.Item
                            wrapperCol={{
                                offset: 1,
                                span: 16,
                            }}
                        >
                            <Button
                                loading={loading}
                                size="large"
                                block
                                type="primary"
                                htmlType="submit"
                            >
                                {loading ? "Saving..." : "Save"}
                            </Button>
                        </Form.Item>
                    </div>
                </div>
            </Form>
        </div>
    );
};

export default AddSliderAgent;
