import React, { useEffect, useState } from "react";
import { Input, Typography, Button, Spin, Form, Switch, Upload, notification } from "antd";
import { get, postFromData, put } from "../../../api/axios";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { ArrowLeftOutlined, UploadOutlined } from "@ant-design/icons";
import ReactQuill from "react-quill";

const { Title } = Typography;

const EditSlider = () => {
    const navigate = useNavigate();
    let { id } = useParams();

    const [fromLoading, setFromLoading] = useState(false);

    const [inputsValue, setInputsValue] = useState({});
    const [fileListAr, setFileListAr] = useState([
        {
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        },
    ]);
    const [fileListEn, setFileListEn] = useState([
        {
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        },
    ]);

    const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
    }


    useEffect(() => {
        get(`sliders/${id}`).then((res) => {
            setInputsValue(res.data.data);
            setFromLoading(true);
            setFileListAr(
                [
                    {
                        uid: '-1',
                        name: 'image.png',
                        status: 'done',
                        url: res.data.data.image_ar,
                    },
                ]
            )
            setFileListEn(
                [
                    {
                        uid: '-1',
                        name: 'image.png',
                        status: 'done',
                        url: res.data.data.image_en,
                    },
                ]
            )
        });
    }, [id]);


    const handleBeforeUploadAr = (e) => {
        setLoading('uploading')
        postFromData('/upload-image', { 'image': e, 'store_path': 'sliders', 'old_path': inputsValue.image_path_ar }).then(res => {
            console.log(res);
            setFileListAr([
                {
                    uid: '-1',
                    name: 'image.png',
                    status: 'done',
                    url: process.env.REACT_APP_BASE_IMAGE_URL + res.data,
                },
            ])
            setInputsValue({ ...inputsValue, image_path_ar: res.data })
            setLoading('');
        })
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoading(false);
            })
    }
    const handleBeforeUploadEn = (e) => {
        setLoading('uploading')
        postFromData('/upload-image', { 'image': e, 'store_path': 'sliders', 'old_path': inputsValue.image_path_en }).then(res => {
            console.log(res);
            setFileListAr([
                {
                    uid: '-1',
                    name: 'image.png',
                    status: 'done',
                    url: process.env.REACT_APP_BASE_IMAGE_URL + res.data,
                },
            ])
            setInputsValue({ ...inputsValue, image_path_en: res.data })
            setLoading('');
        })
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoading(false);
            })
    }


    const [loading, setLoading] = useState('');

    const onFinish = (values) => {
        setLoading('loading');
        put(`sliders/${id}`, { ...values, 'image_ar': inputsValue.image_path_ar, 'image_en': inputsValue.image_path_en })
            .then((res) => {
                notification.success({ message: "Modified successfully" });
                setLoading('');
                navigate("/sliders");
            })
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoading(false);
            });
    };

    return (
        <div>
            <Title level={2} className="text-main mb-2">
                <Link className="text-dark me-3" to="/sliders">
                    <ArrowLeftOutlined />
                </Link>
                Edit Slider
            </Title>
            {fromLoading ? (
                <Form
                    className="wrapper-content"
                    onFinish={onFinish}
                    labelCol={{
                        span: 7,
                    }}
                    wrapperCol={{
                        span: 15,
                    }}
                    initialValues={{
                        title_ar: inputsValue.title_ar,
                        title_en: inputsValue.title_en,
                        order: inputsValue.order,
                        is_active: inputsValue.is_active,
                        description_ar: inputsValue.description_ar,
                        description_en: inputsValue.description_en,
                    }}
                >
                    <div className="row">
                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item
                                label="Title Ar"
                                name="title_ar"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <Input placeholder="Type..." />
                            </Form.Item>
                        </div>
                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item
                                label="Title En "
                                name="title_en"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <Input placeholder="Type..." />
                            </Form.Item>
                        </div>
                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item
                                label="Description Ar "
                                name="description_ar"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <ReactQuill theme="snow" />
                            </Form.Item>
                        </div>
                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item
                                label="Description En "
                                name="description_en"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <ReactQuill theme="snow" />
                            </Form.Item>
                        </div>
                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item
                                label="Order "
                                name="order"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <Input type="number" placeholder="Type..." />
                            </Form.Item>
                        </div>                        
                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item label="Active " name="is_active">
                                <Switch defaultChecked={inputsValue.is_active} />
                            </Form.Item>
                        </div>

                        {/* Item */}
                        <div className='col-lg-6'>
                            <Form.Item
                                label='Image ar'
                            >
                                <Upload
                                    beforeUpload={handleBeforeUploadAr}
                                    listType="picture"
                                    maxCount={1}
                                    defaultFileList={fileListAr}
                                    onPreview={onPreview}

                                >
                                    <Button icon={<UploadOutlined />}> Click to upload an image </Button>
                                </Upload>
                            </Form.Item>
                        </div>
                        {/* Item */}
                        <div className='col-lg-6'>
                            <Form.Item
                                label='Image en'
                            >
                                <Upload
                                    beforeUpload={handleBeforeUploadEn}
                                    listType="picture"
                                    maxCount={1}
                                    defaultFileList={fileListEn}
                                    onPreview={onPreview}

                                >
                                    <Button icon={<UploadOutlined />}> Click to upload an image </Button>
                                </Upload>
                            </Form.Item>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 offset-lg-4">
                            <Form.Item
                                wrapperCol={{
                                    offset: 1,
                                    span: 16,
                                }}
                            >
                                <Button
                                    loading={loading == 'loading' ? true : loading == 'uploading' ? true : false}
                                    size="large"
                                    block
                                    type="primary"
                                    htmlType="submit"
                                >
                                    {loading == 'loading' ? "Updating..." : loading == 'uploading' ? 'Uploading' : "Update"}
                                </Button>
                            </Form.Item>
                        </div>
                    </div>
                </Form>
            ) : (
                <div className="text-center">
                    <Spin size="large" />
                </div>
            )}
        </div>
    );
};

export default EditSlider;