import React, {useState} from "react";
import {
  Button,
  Collapse,
  Divider,
  Form,
  Input,
  notification,
  Switch,
  Typography,
} from "antd";
import {post} from "../../../api/axios";
import {Link, useNavigate} from "react-router-dom";
import {ArrowLeftOutlined} from "@ant-design/icons";
import MapComponent from "../../control-sections/helper/MapComponent";
import UnitsComponent from "./helper/UnitsComponent";
import QuestionsComponent from "./helper/QuestionsComponent";
import DocumentUploadComponent from "../../control-sections/helper/DocumentUploadComponent";
import ImagesUploadComponent from "../../control-sections/helper/ImagesUploadComponent";

const { Title } = Typography;

const {Panel} = Collapse;
const AddAgentProject = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [inputsValue, setInputsValue] = useState({
    is_active: true,
    is_sold_out: false,

  });
  const [markerPosition, setMarkerPosition] = useState(null);
  const [fileArList, setFileArList] = useState([]);
  const [fileEnList, setFileEnList] = useState([]);
  const [imageList, setImageList] = useState([]);
  const [fileUnits, setFileUnits] = useState([]); 
  const [fileQuestions, setFileQuestions] = useState([]);
 

  const handleMarkerPositionChange = (position) => {
    setMarkerPosition(position);
    console.log("Updated Marker Position:", position);
  };
  const handleSwitch = (status, name) => {
    setInputsValue({ ...inputsValue, [name]: status });
  };
  const handleDocumentUpload = (filePath, fileName) => {
    setInputsValue((prev) => ({ ...prev, [fileName]: filePath }));
  };
  const handleDeleteFile = (filePath, fileName) => {
    // Remove the file path from inputsValue
    setInputsValue((prev) => ({ ...prev, [fileName]: null }));
  };
  const handleImageUpload = (imageUrl) => {
    setImageList((prev) => [...prev, imageUrl]);
  };

  const onFinish = (values) => {
     setLoading(true);
    if (!inputsValue.file_ar) {
      notification.error({
        message: 'Missing required file',
        description: 'Please upload Arabic file.',
      });
      setLoading(false);
      return;
    }
    if (!inputsValue.file_en) {
      notification.error({
        message: 'Missing required file',
        description: 'Please upload English file.',
      });
      setLoading(false);
      return;
    }
    if (!imageList.length) {
        notification.error({
            message: 'Missing required images',
            description: 'Please upload at least one image.',
        });
        setLoading(false);
        return;
    }
    const data = {
      ...values,
      ...inputsValue,
      photos: imageList.map((image) => (image.path || image.url)),
      latitude: markerPosition?.lat || inputsValue?.latitude,
      longitude: markerPosition?.lng || inputsValue?.longitude,
    };
    if (data?.questions?.length) {
      data.questions = data.questions.map((question) => ({
        ...question,
        is_active: question.is_active ?? true, // default to true if not provided
      }));
    }

    post("agent-projects", data)
        .then((res) => {
          notification.success({ message: "Added successfully" });
          setLoading(false);
          navigate("/agent-projects");
        })
        .catch((err) => {
          notification.error({
            message: "Error!",
            description: err.response?.data?.message || "Something went wrong.",
          });
          setLoading(false);
        });
  };
  return (
    <div>
      <Title level={2} className="text-main mb-2">
        <Link className="text-dark me-3" to="/agent-projects">
          <ArrowLeftOutlined />
        </Link>
        Add Project
      </Title>
      <Form
        className="wrapper-content"
        onFinish={onFinish}
        labelCol={{
          span: 7,
        }}
        wrapperCol={{
          span: 15,
        }}
        initialValues={{
          remember: true,
        }}
        autoComplete="off"
      >
        <div className="row">
          <Collapse defaultActiveKey={['1']}>
            <Panel header="General Info " key="1">
              <Title level={4} className="mt-3"> General Info  </Title>
              <div className="row">
                {/* Item */}
                <div className="col-lg-6">
                  <Form.Item
                      label="Name Ar"
                      name="name_ar"
                      rules={[
                        {
                          required: true,
                          message: "This field is required!",
                        },
                      ]}
                  >
                    <Input placeholder="Type..."/>
                  </Form.Item>
                </div>
                {/* Item */}
                <div className="col-lg-6">
                  <Form.Item
                      label="Name En "
                      name="name_en"
                      rules={[
                        {
                          required: true,
                          message: "This field is required!",
                        },
                      ]}
                  >
                    <Input placeholder="Type..."/>
                  </Form.Item>
                </div>
                {/* Item */}
                <div className="col-lg-6">
                  <Form.Item
                      label="Developer Ar"
                      name="developer_ar"
                      rules={[
                        {
                          required: true,
                          message: "This field is required!",
                        },
                      ]}
                  >
                    <Input placeholder="Type..."/>
                  </Form.Item>
                </div>
                {/* Item */}
                <div className="col-lg-6">
                  <Form.Item
                      label="Developer En "
                      name="developer_en"
                      rules={[
                        {
                          required: true,
                          message: "This field is required!",
                        },
                      ]}
                  >
                    <Input placeholder="Type..."/>
                  </Form.Item>
                </div>
                {/* Item */}
                <div className="col-lg-6">
                  <Form.Item
                      label="Location Ar"
                      name="location_ar"
                      rules={[
                        {
                          required: true,
                          message: "This field is required!",
                        },
                      ]}
                  >
                    <Input placeholder="Type..."/>
                  </Form.Item>
                </div>
                {/* Item */}
                <div className="col-lg-6">
                  <Form.Item
                      label="Location En "
                      name="location_en"
                      rules={[
                        {
                          required: true,
                          message: "This field is required!",
                        },
                      ]}
                  >
                    <Input placeholder="Type..."/>
                  </Form.Item>
                </div>
                {/* Item */}
                <div className="col-lg-6">
                  <Form.Item
                      label="Price"
                      name="price"
                      rules={[
                        {
                          required: true,
                          message: "This field is required!",
                        }
                      ]}
                  >
                    <Input type="number"
                           placeholder="Type..."/>
                  </Form.Item>
                </div>
                {/* Item */}
                <div className="col-lg-6">
                  <Form.Item
                      label="Order"
                      name="order"
                      rules={[
                        {
                          required: true,
                          message: "This field is required!",
                        }
                      ]}
                  >
                    <Input type="number"
                           placeholder="Type..."/>
                  </Form.Item>
                </div>
                {/* Item */}
                <div className="col-lg-6">
                  <Form.Item
                      label="File Ar"
                      name="file_ar"
                      // rules={[{ required: true, message: 'Please upload the Arabic file!' }]}
                  >
                    <DocumentUploadComponent
                        onFileUpload={(file) => handleDocumentUpload(file, 'file_ar')}
                        onFileDelete={(file) => handleDeleteFile(file, 'file_ar')}
                        fileList={fileArList}
                        setFileList={setFileArList}
                        storagePath={'agent-projects'}
                    />
                  </Form.Item>
                </div>
                <div className="col-lg-6">
                  <Form.Item
                      label="File En"
                      name="file_en"
                      // rules={[{ required: true, message: 'Please upload the English file!' }]}
                  >
                    <DocumentUploadComponent
                        onFileUpload={(file) => handleDocumentUpload(file, 'file_en')}
                        onFileDelete={(file) => handleDeleteFile(file, 'file_en')}
                        fileList={fileEnList}
                        setFileList={setFileEnList}
                        storagePath={'agent-projects'}
                    />
                  </Form.Item>
                </div>
                {/* Item */}
                <div className="col-lg-6">
                  <Form.Item label="Active " name="is_active">
                    <Switch
                        defaultChecked
                        onChange={(status) => handleSwitch(status, "is_active")}
                    />
                  </Form.Item>
                </div>
                {/* Item */}
                <div className="col-lg-6">
                  <Form.Item label="Sold Out " name="is_sold_out">
                    <Switch
                        defaultChecked={false}
                        onChange={(status) => handleSwitch(status, "is_sold_out")}
                    />
                  </Form.Item>
                </div>

                <div className="col-lg-12">
                  <ImagesUploadComponent
                      onImageUpload={handleImageUpload}
                      imageList={imageList}
                      setImageList={setImageList}
                      storagePath={'agent-projects'}
                  />
                </div>
                <MapComponent onMarkerPositionChange={handleMarkerPositionChange} />
              </div>
            </Panel>
            <Divider/>
            <Panel header="Units" key="2">
              <Title level={4} className="mt-3"> Units </Title>
              <Form.Item name="file_units" label="Upload Your Units">
            <DocumentUploadComponent
              onFileUpload={(file) => handleDocumentUpload(file, 'file_units')}
              onFileDelete={(file) => handleDeleteFile(file , 'file_units')}
              fileList={fileUnits}
              setFileList={setFileUnits}
              storagePath={"agent-projects/units"}
            />
           </Form.Item>
              <UnitsComponent  
              
               />
            </Panel>
            <Divider/>
            <Panel header="Questions" key="3">
              <Title level={4} className="mt-3"> Questions </Title>
              <Form.Item name="file_questions"  label="Upload Your Questions">
            <DocumentUploadComponent
              onFileUpload={(file) => handleDocumentUpload(file, 'file_questions')}
              onFileDelete={(file) => handleDeleteFile(file , 'file_questions')}
              fileList={fileQuestions}
              setFileList={setFileQuestions}
              storagePath={"agent-projects/questions"}
            />
           </Form.Item>
              <QuestionsComponent/>
            </Panel>
          </Collapse>

        </div>
        <div className="row mt-5 pt-2">
          <div className="col-lg-4 offset-lg-4">
            <Form.Item
                wrapperCol={{
                  offset: 1,
                  span: 16,
                }}
            >
              <Button
                  loading={loading}
                  size="large"
                  block
                  type="primary"
                  htmlType="submit"
              >
              {loading ? "Saving..." : "Save"}
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default AddAgentProject;
