import React, { useEffect, useState } from "react";
import {
  Input,
  Typography,
  Button,
  Spin,
  Form,
  Switch,
  notification,
} from "antd";
import { get, put } from "../../../api/axios";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { ArrowLeftOutlined } from "@ant-design/icons";

const { Title } = Typography;

const EditAgentTrainingCategory = () => {
  const navigate = useNavigate();
  let { id } = useParams();

  const [fromLoading, setFromLoading] = useState(false);

  const [inputsValue, setInputsValue] = useState({});

  useEffect(() => {
    get(`agent-training-categories/${id}`).then(res => {
      setInputsValue(res.data);
      setFromLoading(true);
    });
  }, [id]);

  const [loading, setLoading] = useState(false);
  const onFinish = values => {
    setLoading(true);

    put(`agent-training-categories/${id}`, values)
      .then(res => {
        notification.success({ message: "Modified successfully" });
        setLoading(false);
        navigate("/agent-training-categories");
      })
      .catch(err => {
        notification.error({
          message: "Error!",
          description: err.response.data.message,
        });
        setLoading(false);
      });
  };

  return (
    <div>
      <Title level={2} className="text-main mb-2">
        <Link className="text-dark me-3" to="/agent-training-categories">
          <ArrowLeftOutlined />
        </Link>
        Edit Agent Training Category
      </Title>
      {fromLoading ? (
        <Form
            layout="vertical"
            className="wrapper-content"
          onFinish={onFinish}
          labelCol={{
            span: 7,
          }}
          wrapperCol={{
            span: 15,
          }}
          initialValues={{
            name_ar: inputsValue.name_ar,
            name_en: inputsValue.name_en,
            timing: inputsValue.timing,
            order: inputsValue.order,
            is_active: inputsValue.is_active,
          }}
        >
          <div className="row">
            {/* Item */}
            <div className="col-lg-6">
              <Form.Item
                label="Name Ar"
                name="name_ar"
                className="full-width-label"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <Input placeholder="Type..." />
              </Form.Item>
            </div>
            {/* Item */}
            <div className="col-lg-6">
              <Form.Item
                  className="full-width-label"
                label="Name En "
                name="name_en"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <Input placeholder="Type..." />
              </Form.Item>
            </div>


            {/* Item */}
            <div className="col-lg-6">
              <Form.Item
                label="Order "
                className="full-width-label"
                name="order"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <Input type="number" placeholder="Type..." />
              </Form.Item>
            </div>
            {/* Item */}
            <div className="col-lg-6">
              <Form.Item
                label="Show this category after X days "
                className="full-width-label"
                name="timing"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <Input type="number" placeholder="Type..." />
              </Form.Item>
            </div>
            {/* Item */}
            <div className="col-lg-6">
              <Form.Item label="Active " className="full-width-label" name="is_active">
                <Switch defaultChecked={inputsValue.is_active} />
              </Form.Item>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 offset-lg-4">
              <Form.Item
                wrapperCol={{
                  offset: 1,
                  span: 16,
                }}
              >
                <Button
                  loading={loading}
                  size="large"
                  block
                  type="primary"
                  htmlType="submit"
                >
                  {loading ? "Updating..." : "Update"}
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      ) : (
        <div className="text-center">
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

export default EditAgentTrainingCategory;
