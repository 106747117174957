import React, { useEffect, useState } from "react";
import { Button, Typography, Form, Select, Input, notification } from "antd";
import { get } from "../../../api/axios";
import { Link } from "react-router-dom";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { checkPermission } from "../../control-sections/auth/ProtectedRoutes";
import { onChangeTableHelper } from "../../control-sections/helper/TableHelpers";
import TableComponent from "../../control-sections/helper/TableComponent";

const StatisticValues = () => {
    const { Title } = Typography;
    const [form] = Form.useForm();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingSearch, setLoadingSearch] = useState(false);

    const [total, setTotal] = useState(1);

    const columns = [
        {
            title: " Id",
            dataIndex: "id",
            sorter: (a, b) => a.id - b.id,
        },
        {
            title: "Statistic",
            dataIndex: "statistic",
            sorter: (a, b) => a.statistic - b.statistic,
        },
        {
            title: "City",
            dataIndex: "city",
            sorter: (a, b) => a.city - b.city,
        },
        {
            title: "Neighborhood",
            dataIndex: "neighborhood",
            sorter: (a, b) => a.city - b.city,
        },
        {
            title: "Unit Type",
            dataIndex: "unit_type",
            sorter: (a, b) => a.city - b.city,
        },
        {
            title: "Value",
            dataIndex: "value",
            sorter: (a, b) => a.value - b.value,
        },
        {
            title: "Year",
            dataIndex: "year",
            sorter: (a, b) => a.year - b.year,
        },
    ];
    if (checkPermission("update-statistic-values")) {
        columns.push({
            title: "Actions",
            dataIndex: "id",
            render: (id) => (
                <div>
                    {checkPermission("update-statistic-values") ? (
                        <Button className="btn btn-outline-warning btn-edit btn-sm p-0 me-3">
                            <Link
                                className="color-inherit text-decoration-none py-1 px-2 d-block"
                                to={`/statistic-values/edit/${id}`}
                            >
                                <EditOutlined />
                            </Link>
                        </Button>
                    ) : (
                        <></>
                    )}
                </div>
            ),
        });
    }

    useEffect(() => {
        get("statistic-values").then((res) => {
            setData(res.data.data);
            setTotal(res.data.meta.total);
            setLoading(false);
        });
    }, []);


    const [filterData, setFilterData] = useState({
        filter_key: "",
        filter_value: "",
        filter_key2: "",
        filter_value2: "",
        filter_key3: "",
        filter_value3: "",
    });

    const [sorter, setSorter] = useState({
        sort_by: "",
        order: "",
    });

    const handleFilterKey = (e, name) => {
        setFilterData({ ...filterData, [name]: e });
        // console.log(name, e);
        // console.log('name', name);
    };

    const handleFilterVal = (e) => {
        setFilterData({ ...filterData, [e.target.id]: e.target.value });
    };

    const onFilter = (values) => {
        setLoadingSearch(true);

        get(
            `statistic-values?${filterData.filter_key !== '' && filterData.filter_value !== '' ? `filter_key=${filterData.filter_key}&filter_value=${filterData.filter_value}` : ''
            }${filterData.filter_key2 !== '' && filterData.filter_value2 !== '' ? `&filter_key2=${filterData.filter_key2}&filter_value2=${filterData.filter_value2}` : ''
            }${filterData.filter_key3 !== '' && filterData.filter_value3 !== '' ? `&filter_key3=${filterData.filter_key3}&filter_value3=${filterData.filter_value3}` : ''}
            ${sorter.sort_by !== "" && sorter.sort_by !== undefined ? `&sort_by=${sorter.sort_by}` : ""}${sorter.order !== "" ? `&order=${sorter.order}` : ""
            }`
        )
            .then((res) => {
                setData(res.data.data);
                setTotal(res.data.meta.total);
                setLoadingSearch(false);
            })
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoadingSearch(false);
            });
    };

    const clearSearch = () => {
        form.resetFields();
        setFilterData({
            filter_key: "",
            filter_value: "",
        });
        get("statistic-values").then((res) => {
            setData(res.data.data);
            setTotal(res.data.meta.total);
        });
    };


    const onChangeTable = (pagination, filters, sorter) => {
        setLoading(true);

        setSorter({
            ...sorter,
            sort_by: sorter.field,
            order: sorter.order == "descend" ? "desc" : "asc",
        });
        onChangeTableHelper('statistic-values', pagination, filterData, sorter)
            .then((res) => {
                setData(res.data.data);
                setTotal(res.data.meta.total);
                setLoading(false);
            })
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoading(false);
            });
    };

    return (
        <div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="row">
                        <div className="col-6 col-lg-4">
                            <Title level={2} className="text-main mb-2">
                                {" "}
                                Statistic Values
                                {" "}
                            </Title>
                        </div>
                        {checkPermission("create-statistic-values") ? (
                            <div className="col-6 col-lg-3 offset-lg-5 text-end">
                                <Button type="primary" className="p-0" size="large">
                                    <Link
                                        className="text-decoration-none h-100 flex-center py-2 px-4"
                                        to="/statistic-values/add"
                                    >
                                        {" "}
                                        Add Statistic Values
                                        {" "}
                                    </Link>
                                </Button>
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            </div>

            <div className="grid-margin stretch-card wrapper-content">
                <Form onFinish={onFilter} form={form}>
                    {/* select1 */}
                    <div className="row">
                        <div className="col-12 col-lg-4">
                            <Form.Item
                                label="Search By"
                                name="filter_key"
                            >
                                <Select
                                    className="w-100"
                                    showSearch
                                    placeholder="select "
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label ?? "")
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                    onChange={(e) => handleFilterKey(e, 'filter_key')}
                                    options={[
                                        {
                                            value: "id",
                                            label: "Id",
                                        },
                                        {
                                            value: "statistic_name",
                                            label: "Statistic",
                                        },
                                        {
                                            value: "city_name",
                                            label: "City",
                                        },
                                        {
                                            value: "neighborhood_name",
                                            label: "Neighborhood",
                                        },
                                        {
                                            value: "unit_type_name",
                                            label: "Unit Type",
                                        },
                                        {
                                            value: "value",
                                            label: "value",
                                        },
                                        {
                                            value: "year",
                                            label: "year",
                                        },
                                    ]}
                                />
                            </Form.Item>
                        </div>
                        <div className="col-12 col-lg-6">
                            <Form.Item
                                label="Search"
                                name="filter_value"
                                onChange={handleFilterVal}

                            >
                                <Input placeholder="Type to search..." />
                            </Form.Item>
                        </div>

                    </div>
                    {/* select2 */}
                    <div className="row">
                        <div className="col-12 col-lg-4">
                            <Form.Item
                                label="Search By"
                                name="filter_key2"
                            >
                                <Select
                                    className="w-100"
                                    showSearch
                                    placeholder="select test "
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label ?? "")
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                    onChange={(e) => handleFilterKey(e, 'filter_key2')}
                                    options={[
                                        {
                                            value: "id",
                                            label: "Id",
                                        },
                                        {
                                            value: "statistic_name",
                                            label: "Statistic",
                                        },
                                        {
                                            value: "city_name",
                                            label: "City",
                                        },
                                        {
                                            value: "neighborhood_name",
                                            label: "Neighborhood",
                                        },
                                        {
                                            value: "unit_type_name",
                                            label: "Unit Type",
                                        },
                                        {
                                            value: "value",
                                            label: "value",
                                        },
                                        {
                                            value: "year",
                                            label: "year",
                                        },
                                    ]}
                                />
                            </Form.Item>
                        </div>
                        <div className="col-12 col-lg-6">
                            <Form.Item
                                label="Search"
                                name="filter_value2"
                                onChange={handleFilterVal}
                            >
                                <Input placeholder="Type to search..." />
                            </Form.Item>
                        </div>
                    </div>
                    {/* select3 */}
                    <div className="row">
                        <div className="col-12 col-lg-4">
                            <Form.Item
                                label="Search By"
                                name="filter_key3"
                            >
                                <Select
                                    className="w-100"
                                    showSearch
                                    placeholder="select test "
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label ?? "")
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                    onChange={(e) => handleFilterKey(e, 'filter_key3')}
                                    options={[
                                        {
                                            value: "id",
                                            label: "Id",
                                        },
                                        {
                                            value: "statistic_name",
                                            label: "Statistic",
                                        },
                                        {
                                            value: "city_name",
                                            label: "City",
                                        },
                                        {
                                            value: "neighborhood_name",
                                            label: "Neighborhood",
                                        },
                                        {
                                            value: "unit_type_name",
                                            label: "Unit Type",
                                        },
                                        {
                                            value: "value",
                                            label: "value",
                                        },
                                        {
                                            value: "year",
                                            label: "year",
                                        },
                                    ]}
                                />
                            </Form.Item>
                        </div>
                        <div className="col-12 col-lg-6">
                            <Form.Item
                                label="Search"
                                name="filter_value3"
                                onChange={handleFilterVal}
                            >
                                <Input placeholder="Type to search..." />
                            </Form.Item>
                        </div>
                        <div className="col-12 col-lg-2 d-flex align-items-end">
                            <Form.Item className="w-100">
                                <div className="row">
                                    <div className="col-8">
                                        <Button
                                            block
                                            loading={loadingSearch}
                                            type="primary"
                                            htmlType="submit"
                                        >
                                            {loadingSearch ? "Searching" : "Search"}
                                        </Button>
                                    </div>
                                    <div className="col-4">
                                        <Button
                                            onClick={clearSearch}
                                            className="flex-center"
                                            danger
                                            type="primary"
                                        >
                                            <DeleteOutlined />
                                        </Button>
                                    </div>
                                </div>
                            </Form.Item>
                        </div>
                    </div>
                </Form>
                <TableComponent onChangeTable={onChangeTable} loading={loading} columns={columns} data={data} total={total} />
            </div>
        </div>
    );
};

export default StatisticValues;
