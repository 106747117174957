import React, {useCallback, useEffect, useState} from "react";
import { Button, Typography, Form, Select, Input, notification } from "antd";
import { del, get } from "../../../api/axios";
import { Link } from "react-router-dom";
import { Popconfirm } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { checkPermission } from "../../control-sections/auth/ProtectedRoutes";
import { onChangeTableHelper } from "../../control-sections/helper/TableHelpers";
import TableComponent from "../../control-sections/helper/TableComponent";

const AgentCustomers = () => {
    const { Title } = Typography;
    const [form] = Form.useForm();
    const queryParams = new URLSearchParams(window.location.search);
    const userId = queryParams.get('user_id');

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingSearch, setLoadingSearch] = useState(false);

    const [total, setTotal] = useState(1);

    const columns = [
        {
            title: " Id",
            dataIndex: "id",
            sorter: (a, b) => a.id - b.id,
        },
        {
            title: "Campaign",
            dataIndex: "campaign",
            render: (campaign) => (
                campaign ? <Link to={`/agent-campaigns/edit/${campaign.id}`}>{campaign.id}</Link> : ''
            ),
        },
        {
            title: "Agent Name",
            dataIndex: "agent",
            render: (agent) => (
                agent ? <Link to={`/agent-users/edit/${agent.id}`}>{agent.name}</Link> : ''

            ),
        },
        {
            title: "Name",
            dataIndex: "name",
            sorter: (a, b) => a.name - b.name,
        },
        {
            title: "Phone Number",
            dataIndex: "phone_number",
            sorter: (a, b) => a.phone_number - b.phone_number,
        },
        {
            title: "Utm Source",
            dataIndex: "utm_source",
            sorter: (a, b) => a.utm_source - b.utm_source,
        },
        {
            title: "Utm Medium",
            dataIndex: "utm_medium",
            sorter: (a, b) => a.utm_medium - b.utm_medium,
        },
        {
            title: "Utm Campaign",
            dataIndex: "utm_campaign",
            sorter: (a, b) => a.utm_campaign - b.utm_campaign,
        },
        {
            title: "Utm Content",
            dataIndex: "utm_content",
            sorter: (a, b) => a.utm_content - b.utm_content,
        },
        // {
        //     title: "Notes",
        //     dataIndex: "notes",
        //     sorter: (a, b) => a.notes - b.notes,
        // },
        {
            title: "Creation Date",
            dataIndex: "created_at",
            sorter: (a, b) => a.created_at - b.created_at,
        },
    ];

    if (
        checkPermission("update-agent-customer") ||
        checkPermission("delete-agent-customer")
    ) {
        columns.push({
            title: "Actions",
            dataIndex: "id",
            render: (id) => (
                <div>
                    {checkPermission("update-agent-customer") ? (
                        <Button className="btn btn-outline-warning btn-edit btn-sm p-0 me-3">
                            <Link
                                className="color-inherit text-decoration-none py-1 px-2 d-block"
                                to={`/agent-customers/edit/${id}`}
                            >
                                <EditOutlined />
                            </Link>
                        </Button>
                    ) : (
                        <></>
                    )}
                    {checkPermission("delete-agent-customer") ? (
                        <Popconfirm
                            title="Delete item"
                            description="Are you sure you want to delete this item?"
                            onConfirm={() => confirm(id)}
                            onCancel={cancel}
                            okText="OK"
                            cancelText="Cancel"
                        >
                            <Button danger className="flex-center d-inline-flex">
                                <DeleteOutlined />
                            </Button>
                        </Popconfirm>
                    ) : (
                        <></>
                    )}
                </div>
            ),
        });
    }

    const [filterData, setFilterData] = useState({
        filter_key: "",
        filter_value: "",
    });

    const [sorter, setSorter] = useState({
        sort_by: "id",
        order: "desc",
    });
    useEffect(() => {
        if (userId !== null) {
            setFilterData({
                filter_key: "agent_id",
                filter_value: userId,
            });
        }
    }, []);
    const handleFilterKey = (e) => {
        setFilterData({ ...filterData, filter_key: e });
    };

    const handleFilterVal = (e) => {
        setFilterData({ ...filterData, [e.target.id]: e.target.value });
    };

    const fetchData = useCallback(() => {
        let url = 'agent-customers?';
        if (
            filterData.filter_key !== "" && filterData.filter_key !== undefined &&
            filterData.filter_value !== "" && filterData.filter_value !== undefined
        ) {
            url += `filter_key=${filterData.filter_key}&filter_value=${filterData.filter_value}&`;
        }

        if (sorter.sort_by !== "" && sorter.sort_by !== undefined) {
            url += `sort_by=${sorter.sort_by}&order=${sorter.order}&`;
        }

        if (filterData.filter_key !== '' && filterData.filter_value == '') {
            console.log('filter value is empty');
        } else {
            setLoading(true);
            get(url)
                .then((res) => {
                    setData(res.data.data);
                    setTotal(res.data.meta.total);
                    setLoadingSearch(false);
                    setLoading(false);
                });
        }
    }, [filterData, sorter]); // Dependencies of fetchData

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleDelete = (id) => {
        del(`agent-customers/${id}`)
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoading(false);
            });
        let dateFilter = data.filter((item) => item.id !== id);
        setData(dateFilter);
    };

    const confirm = (id) => {
        handleDelete(id);
        notification.success({ message: "It was successful" });
    };

    const cancel = (e) => {
        notification.info({ message: "The order has been cancelled" });
    };

    const onFilter = (values) => {
        setLoadingSearch(true);
       fetchData();
    };

    const clearSearch = () => {
        form.resetFields();
        setFilterData({
            filter_key: "",
            filter_value: "",
        });
        fetchData();
    };

    const onChangeTable = (pagination, filters, sorter) => {
        setLoading(true);
        setSorter({
            ...sorter,
            sort_by: sorter.field,
            order: sorter.order == "descend" ? "desc" : "asc",
        });

        onChangeTableHelper('agent-customers', pagination, filterData, sorter)
            .then((res) => {
                setData(res.data.data);
                setTotal(res.data.meta.total);
                setLoading(false);
            })
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoading(false);
            });
    };

    return (
        <div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="row">
                        <div className="col-lg-6">
                            <Title level={2} className="text-main mb-2">
                                Agent Customers
                            </Title>
                        </div>
                        {/* {checkPermission("create-agent-customer") ? ( */}
                        <div className="col-lg-3 offset-lg-3 text-end">
                            <Button type="primary" className="p-0" size="large">
                                <Link
                                    className="text-decoration-none h-100 flex-center py-2 px-4"
                                    to="/agent-customers/add"
                                >
                                    Add Agent Customer
                                </Link>
                            </Button>
                        </div>
                        {/* ) : (
                            <></>
                        )} */}
                    </div>
                </div>
            </div>

            <div className="grid-margin stretch-card wrapper-content">
                <Form onFinish={onFilter} layout="vertical" form={form}>
                    <div className="row">
                        <div className="col-12 col-lg-4">
                            <Form.Item
                                label="Search By"
                                name="filter_key"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <Select
                                    className="w-100"
                                    showSearch
                                    placeholder="select test "
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label ?? "")
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                    onChange={handleFilterKey}
                                    options={[
                                        {
                                            value: "agent_name",
                                            label: "Agent Name",
                                        },
                                        {
                                            value: "agent_phone",
                                            label: "Agent Phone",
                                        },
                                        {
                                            value: "interested_project_id",
                                            label: "Project Id",
                                        },
                                        {
                                            value: "interested_project_name",
                                            label: "Project Name",
                                        },
                                        {
                                            value: "interested_unit_id",
                                            label: "Unit Id",
                                        },
                                        {
                                            value: "interested_unit_number",
                                            label: "Unit Number",
                                        },
                                        {
                                            value: "name",
                                            label: "Customer Name",
                                        },
                                        {
                                            value: "phone_number",
                                            label: "Customer Phone",
                                        },
                                        {
                                            value: "id",
                                            label: "Customer Id",
                                        },
                                        {
                                            value: "agent_id",
                                            label: "Agent Id",
                                        },
                                    ]}
                                />
                            </Form.Item>
                        </div>
                        <div className="col-12 col-lg-6">
                            <Form.Item
                                label="Search"
                                name="filter_value"
                                onChange={handleFilterVal}
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <Input placeholder="Type to search..." />
                            </Form.Item>
                        </div>
                        <div className="col-12 col-lg-2 d-flex align-items-end">
                            <Form.Item className="w-100">
                                <div className="row">
                                    <div className="col-8">
                                        <Button
                                            block
                                            loading={loadingSearch}
                                            type="primary"
                                            htmlType="submit"
                                        >
                                            {loadingSearch ? "Searching" : "Search"}
                                        </Button>
                                    </div>
                                    <div className="col-4">
                                        <Button
                                            onClick={clearSearch}
                                            className="flex-center"
                                            danger
                                            type="primary"
                                        >
                                            <DeleteOutlined />
                                        </Button>
                                    </div>
                                </div>
                            </Form.Item>
                        </div>
                    </div>
                </Form>
                <TableComponent onChangeTable={onChangeTable} loading={loading} columns={columns} data={data} total={total} />
            </div>
        </div>
    );
};

export default AgentCustomers;
